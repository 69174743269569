import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ConfigProvider,
  Divider,
  Form,
  InputNumber,
  Row,
  Select,
  Spin,
  Switch,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function AssignOperation({
  clientOptions,
  setShowOperationAssignModal,
  referenceItem,
  referenceId,
  referenceType,
  fetchData,
  form,
}: any) {
  const navigate = useNavigate();
  const [storedReferenceItem, setStoredReferenceItem] = useState<any>();
  const [operationLoading, setOperationLoading] = useState<boolean>(false);
  const [description, setDescription] = useState<string>();
  const [txHash, setTxHash] = useState<string>(referenceItem?.txHash);
  const [walletAddress, setWalletAddress] = useState<string>(
    referenceItem?.walletAddress,
  );
  const [operationItemTypeList, setOperationItemTypeList] = useState<any[]>([]);
  const [loadingOpertionItemType, setLoadingOperationItemType] =
    useState<boolean>(true);
  const [selectedOperationItemType, setSelecteOperationItemType] =
    useState<string>();
  const [selectedOperationItemTypeObj, setSelectedOperationItemTypeObj] =
    useState<any>();
  const [multipleOperations, setMultipleOperations] = useState<any[]>([
    { ...referenceItem },
  ]);
  const [everyMultiHasOperation, setEveryMultiHasOperation] =
    useState<boolean>(false);
  const [selectedOperationType, setSelectedOperationType] = useState<any>();

  const fetchOperationItemType = useCallback(() => {
    if (!referenceType) return;
    setLoadingOperationItemType(true);
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/item-types",
      params: { reference: referenceType },
      withCredentials: true,
    })
      .then((res) => {
        setOperationItemTypeList(res.data.operationItemTypes);
        if (res.data.operationItemTypes?.length === 1) {
          setSelecteOperationItemType(res.data.operationItemTypes[0].value);
          setSelectedOperationItemTypeObj(res.data.operationItemTypes[0]);
        } else {
          if (referenceItem.assetType && referenceItem.transactionWay) {
            const txWay = referenceItem.transactionWay?.replace("virtual ", "");
            const item = res.data.operationItemTypes?.find(
              (e: any) =>
                e.label?.toLocaleLowerCase() ===
                `${referenceItem.assetType.toLocaleLowerCase()} ${txWay}`,
            );
            setSelecteOperationItemType(item ? item.value : undefined);
            setSelectedOperationItemTypeObj(item ? item : undefined);
          } else if (referenceItem.transactionWay) {
            let item;
            if (referenceItem.transactionWay === "Debit") {
              item = res.data.operationItemTypes?.find(
                (e: any) =>
                  e.label?.toLocaleLowerCase() === `ebanking withdrawal`,
              );
            } else if (referenceItem.transactionWay === "Credit") {
              item = res.data.operationItemTypes?.find(
                (e: any) => e.label?.toLocaleLowerCase() === `ebanking deposit`,
              );
            }
            setSelecteOperationItemType(item?.value);
            setSelectedOperationItemTypeObj(item ? item : undefined);
          }
        }
      })
      .finally(() => {
        setLoadingOperationItemType(false);
      });
  }, [referenceType, referenceItem]);

  const [sending, setSending] = useState<boolean>(false);

  useEffect(() => {
    fetchOperationItemType();
  }, [fetchOperationItemType]);

  const fetchOperationWithGivenClientId = useCallback(
    (clientId: number, opGroup: any) => {
      setOperationLoading(true);
      axios({
        method: "Get",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations",
        params: {
          clients: JSON.stringify([clientId]),
          operationStatus: "Open",
        },
        withCredentials: true,
      })
        .then((res) => {
          if (!res.data.operations || !res.data.operations.length) {
            opGroup.operationList = [];
            return;
          }
          let operations = res.data.operations;
          if (selectedOperationType) {
            operations = operations.filter(
              (row: any) => row.operationTypeId === selectedOperationType,
            );
          }
          const options = operations.map((row: any) => ({
            key: row.id,
            label: row.label,
            value: row.id,
          }));
          opGroup.operationList = options;
        })
        .finally(() => {
          setOperationLoading(false);
        });
    },
    [selectedOperationType],
  );

  useEffect(() => {
    setStoredReferenceItem({ ...referenceItem });
    setWalletAddress(referenceItem?.walletAddress);
    setTxHash(referenceItem?.txHash);
    setMultipleOperations([referenceItem]);
  }, [referenceItem]);

  const [selectedOperation, setSelectedOperation] = useState<number>();
  const resetFields = useCallback(() => {
    setSelectedOperation(undefined);
    setDescription(undefined);
    setSelecteOperationItemType(undefined);
    setSelectedOperationItemTypeObj(undefined);
    setStoredReferenceItem(undefined);
  }, []);

  useEffect(() => {
    resetFields();
  }, [clientOptions, resetFields]);

  const saveOperationItem = useCallback(() => {
    if (!multipleOperations.every((row) => row.selectedOperation)) {
      toast.warn("Please select an operation");
      return;
    }
    for (const opItem of multipleOperations) {
      delete opItem.operationList;
    }
    setSending(true);

    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/item",
      data: {
        multipleOperations: multipleOperations,
        operationItemTypeId: selectedOperationItemType,
        referenceId: referenceId,
        referenceType: referenceType,
        operationId: selectedOperation,
        description: description,
        walletAddress,
        txHash,
        // isClientTransfer: isClientTransfer,
      },
      withCredentials: true,
    })
      .then(() => {
        toast.success("Saved Succssfully");
        setSending(false);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
        setSending(false);
      })
      .finally(() => {
        resetFields();
        setShowOperationAssignModal(false);
        fetchData(form);
      });
  }, [
    description,
    fetchData,
    form,
    multipleOperations,
    navigate,
    referenceId,
    referenceType,
    resetFields,
    selectedOperation,
    selectedOperationItemType,
    setShowOperationAssignModal,
    txHash,
    walletAddress,
  ]);

  const InsertNewOperationItem = useCallback(() => {
    // Deep copy reference item
    const newItem = JSON.parse(JSON.stringify(storedReferenceItem));
    const newMultiOperation = [...multipleOperations];
    let storedAmount = storedReferenceItem.amount;
    if (
      selectedOperationItemTypeObj &&
      selectedOperationItemTypeObj.subtypes &&
      selectedOperationItemTypeObj.subtypes.length
    ) {
      for (const subType of selectedOperationItemTypeObj.subtypes) {
        if (!subType.addOnLabel) {
          const totalAmount = newMultiOperation.reduce(
            (accumulator, currentObject) => {
              return accumulator + currentObject.amount;
            },
            0,
          );
          // newItem.amount =  storedAmount - totalAmount;
          newItem.amount = storedAmount - totalAmount;
          continue;
        }
        const label = subType.addOnLabel.toLocaleLowerCase();
        const totalSubAmount = newMultiOperation.reduce(
          (accumulator, currentObject) => {
            return accumulator + currentObject[label]?.amount;
          },
          0,
        );
        newItem[label].amount =
          storedReferenceItem[label].amount - totalSubAmount;
      }
    }
    newMultiOperation.push(newItem);
    setEveryMultiHasOperation(false);
    setMultipleOperations(newMultiOperation);
  }, [storedReferenceItem, multipleOperations, selectedOperationItemTypeObj]);

  return (
    <Content id={"assgin-opeartion"}>
      <ConfigProvider
        theme={{
          components: {
            Form: {
              labelColor: "white",
            },
            InputNumber: {
              /* here is your component tokens */
              addonBg: "lightgrey",
            },
          },
          token: {
            colorBgContainerDisabled: "lightgrey",
            colorSplit: "white",
          },
        }}
      >
        <Row
          justify={"center"}
          style={{
            marginTop: "20px",
          }}
        >
          <h2 style={{ color: "white" }}>Operation</h2>
        </Row>
        <Form labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} labelWrap>
          <Form.Item label={<b>Type</b>} required>
            {loadingOpertionItemType ? (
              <Spin />
            ) : (
              <Select
                options={operationItemTypeList}
                value={selectedOperationItemType}
                onChange={(e, option) => {
                  setMultipleOperations([
                    { ...referenceItem, selectedClient: undefined },
                  ]);
                  setSelectedOperationItemTypeObj(option);
                  setSelecteOperationItemType(e);
                  setSelectedOperationType(option.operationTypeId);
                }}
                showSearch
                filterOption={(input, option: any) =>
                  option.label
                    ?.toLocaleLowerCase()
                    .includes(input.toLocaleLowerCase())
                }
              />
            )}
          </Form.Item>
          <Form.Item label={<b>Description</b>}>
            <TextArea
              rows={3}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Form.Item>
          {referenceType === "venue_ledger" ? (
            <Form.Item label={<b>Address</b>}>
              <TextArea
                rows={3}
                value={walletAddress}
                onChange={(e) => {
                  setWalletAddress(e.target.value);
                }}
              />
            </Form.Item>
          ) : undefined}
          {referenceType === "venue_ledger" ? (
            <Form.Item label={<b>Txid</b>}>
              <TextArea
                rows={3}
                value={txHash}
                onChange={(e) => {
                  setTxHash(e.target.value);
                }}
              />
            </Form.Item>
          ) : undefined}
          <React.Fragment>
            {multipleOperations.map((opGroup: any, index: number) => (
              <React.Fragment key={index}>
                <Divider />
                <Form.Item label={<b>Client</b>}>
                  <Select
                    options={clientOptions}
                    value={opGroup.selectedClient}
                    onChange={(e) => {
                      opGroup.selectedClient = e;
                      opGroup.selectedOperation = undefined;
                      fetchOperationWithGivenClientId(e, opGroup);
                    }}
                    showSearch
                    filterOption={(input, option: any) =>
                      option.label
                        ?.toLocaleLowerCase()
                        .includes(input.toLocaleLowerCase())
                    }
                  />
                </Form.Item>
                <Form.Item label={<b>Operation</b>} required>
                  {operationLoading ? (
                    <Spin />
                  ) : (
                    <Select
                      disabled={
                        !opGroup?.selectedClient ||
                        !opGroup.operationList?.length
                      }
                      dropdownStyle={{
                        backgroundColor: "white",
                      }}
                      options={opGroup.operationList}
                      value={opGroup.selectedOperation}
                      onChange={(e) => {
                        opGroup.selectedOperation = e;
                        setEveryMultiHasOperation(
                          multipleOperations.every(
                            (tx) => tx.selectedOperation,
                          ),
                        );
                      }}
                      showSearch
                      filterOption={(input, option: any) =>
                        option.label
                          ?.toLocaleLowerCase()
                          .includes(input.toLocaleLowerCase())
                      }
                    />
                  )}
                </Form.Item>
                {selectedOperationItemTypeObj &&
                selectedOperationItemTypeObj.subtypes &&
                selectedOperationItemTypeObj.subtypes.length ? (
                  selectedOperationItemTypeObj.subtypes.map(
                    (subType: any, subTypeIndex: number) => (
                      <Form.Item
                        key={subTypeIndex}
                        label={
                          <b>
                            {subType.addOnLabel ? subType.addOnLabel : "Amount"}
                          </b>
                        }
                        help={
                          multipleOperations.length > 1 ? (
                            <p
                              style={{
                                margin: "0px",
                                fontSize: "12px",
                              }}
                            >
                              <b>
                                Initial{" "}
                                {subType.addOnLabel !== ""
                                  ? subType.addOnLabel.toLocaleLowerCase()
                                  : "amount"}
                                :
                              </b>{" "}
                              {subType.addOnLabel !== ""
                                ? storedReferenceItem[
                                    subType.addOnLabel.toLocaleLowerCase()
                                  ]?.amount?.toLocaleString("en-us", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : storedReferenceItem.amount?.toLocaleString(
                                    "en-us",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    },
                                  )}
                            </p>
                          ) : (
                            <p></p>
                          )
                        }
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          controls={false}
                          formatter={(value) => {
                            const parts = `${value}`.split(".");
                            // Apply the original regex only to the first part (integer part) to add commas.
                            const integerPartWithCommas = parts[0].replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ",",
                            );
                            // Reassemble the parts, including the decimal part if it was present.
                            return parts.length > 1
                              ? `${integerPartWithCommas}.${parts[1]}`
                              : integerPartWithCommas;
                          }}
                          parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                          value={
                            subType.addOnLabel
                              ? opGroup[subType.addOnLabel.toLocaleLowerCase()]
                                  ?.amount
                              : opGroup.amount
                          }
                          onChange={(e) => {
                            if (subType.addOnLabel) {
                              opGroup[subType.addOnLabel.toLocaleLowerCase()] =
                                {
                                  amount: e,
                                  assetId:
                                    storedReferenceItem?.[
                                      subType.addOnLabel.toLocaleLowerCase()
                                    ].assetId,
                                };
                            } else {
                              opGroup.amount = e;
                              opGroup.assetId = storedReferenceItem.assetId;
                            }
                            setMultipleOperations([...multipleOperations]);
                          }}
                          addonAfter={
                            subType.addOnLabel
                              ? referenceItem?.[
                                  subType.addOnLabel.toLocaleLowerCase()
                                ]?.assetName
                                ? referenceItem?.[
                                    subType.addOnLabel.toLocaleLowerCase()
                                  ]?.assetName
                                : referenceItem?.assetName
                              : referenceItem?.assetName
                          }
                        />
                      </Form.Item>
                    ),
                  )
                ) : (
                  <></>
                )}
                {opGroup.fee?.amount > 0 ? (
                  <Form.Item label={<b>Fees borne by Delchain</b>}>
                    <Switch
                      checked={opGroup.isFeeAssignedToDelchain}
                      onChange={(e) => {
                        opGroup.isFeeAssignedToDelchain = e;
                      }}
                    />
                  </Form.Item>
                ) : (
                  <></>
                )}
                {selectedOperationItemTypeObj &&
                selectedOperationItemTypeObj.displaySwitch ? (
                  <Form.Item label={<b>Is Client Transfer</b>}>
                    <Switch
                      disabled={!selectedOperationItemTypeObj.displaySwitch}
                      checked={opGroup.isClientTransfer}
                      onChange={(e) => {
                        multipleOperations[index] = {
                          ...opGroup,
                          isClientTransfer: e,
                        };
                        setMultipleOperations([...multipleOperations]);
                      }}
                    />
                  </Form.Item>
                ) : (
                  ""
                )}
                {opGroup.isClientTransfer &&
                referenceItem?.transactionWay?.startsWith("virtual") ? (
                  <Form.Item
                    label={
                      <b>
                        {referenceItem?.transactionWay?.includes("withdrawal")
                          ? "To"
                          : "From"}{" "}
                        Venue Wallet?
                      </b>
                    }
                  >
                    <Switch
                      checked={opGroup.isVenueWalletRelated}
                      onChange={(e) => {
                        multipleOperations[index] = {
                          ...opGroup,
                          isVenueWalletRelated: e,
                        };
                        setMultipleOperations([...multipleOperations]);
                      }}
                    />
                  </Form.Item>
                ) : (
                  <></>
                )}
                {
                  //DELETE on index > 0
                  index > 0 ? (
                    <Form.Item
                      colon={false}
                      label={
                        /// Delete
                        <Button
                          danger
                          onClick={() => {
                            multipleOperations.splice(index, 1);
                            setEveryMultiHasOperation(
                              multipleOperations.every(
                                (tx) => tx.selectedOperation,
                              ),
                            );
                            setMultipleOperations([...multipleOperations]);
                          }}
                          style={{
                            border: "none",
                            background: "none",
                            boxShadow: "none",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={"fa-regular fa-square-minus" as IconProp}
                            style={{ fontSize: "2em" }}
                          />
                        </Button>
                      }
                    />
                  ) : (
                    <></>
                  )
                }
              </React.Fragment>
            ))}
            <Divider />

            <Form.Item
              colon={false}
              label={
                <Button
                  onClick={() => {
                    InsertNewOperationItem();
                  }}
                  style={{
                    border: "none",
                    background: "none",
                    boxShadow: "none",
                  }}
                >
                  <FontAwesomeIcon
                    icon={"fa-regular fa-square-plus" as IconProp}
                    style={{ fontSize: "2em" }}
                  />
                </Button>
              }
            >
              <b style={{ color: "white" }}>Split Operation</b>
            </Form.Item>
          </React.Fragment>
        </Form>
        <Row justify={"center"}>
          <Button
            onClick={() => {
              resetFields();
              setShowOperationAssignModal(false);
            }}
            loading={sending}
          >
            Cancel
          </Button>
          <Button
            disabled={!selectedOperationItemType || !everyMultiHasOperation}
            type="primary"
            onClick={() => {
              saveOperationItem();
            }}
            loading={sending}
          >
            Save
          </Button>
        </Row>
      </ConfigProvider>
    </Content>
  );
}
