import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Pagination, Row, Space, Table, Form, Select } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DisplayContext } from "../../../Context/displayContext";

export default function ViewVenueMarketPrices() {
  const navigate = useNavigate();
  const [filterForm] = Form.useForm();
  const displayContext = useContext(DisplayContext);
  const [onFinishLoad, setOnFinishLoad] = useState<boolean>(false);
  // Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [currPageSize, setCurrPageSize] = useState(100);
  const [priceCount, setPriceCount] = useState();

  const [baseAssetOptions, setBaseAssetOptions] = useState<any[]>();
  const [quoteAssetOptions, setQuoteAssetOptions] = useState<any[]>();
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const [selectedFilter, setSelectedFilter] = useState<any>();
  const [venueList, setVenueList] = useState<any[]>();

  const applyFilter = useCallback((form: any) => {
    setSelectedFilter(form);
  }, []);

  const getAssetList = useCallback((params = {}) => {
    setOnFinishLoad(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/",
      params: {
        inMarketPrice: true,
      },
      withCredentials: true,
    })
      .then((res) => {
        const baseOptions = [],
          quoteAssetOptions = [];
        for (const asset of res.data.assetList.baseAssetIds) {
          baseOptions.push({
            label: `${asset.name} (${asset.ticker})`,
            value: asset.id,
          });
        }
        for (const asset of res.data.assetList.quoteAssetIds) {
          quoteAssetOptions.push({
            label: `${asset.name} (${asset.ticker})`,
            value: asset.id,
          });
        }
        setBaseAssetOptions(baseOptions);
        setQuoteAssetOptions(quoteAssetOptions);
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  }, []);

  const getVenueList = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/",
      params: { onlyInMarketTrade: true },
      withCredentials: true,
    })
      .then((res) => {
        setVenueList(res.data.list);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);

  useEffect(() => {
    getAssetList();
    getVenueList();
  }, [getAssetList, getVenueList]);

  const [tableData, setTableData] = useState<any[]>([]);

  const fetchData = useCallback(
    (
      params = {
        offset: 0,
        pageSize: 100,
      },
    ) => {
      setOnFinishLoad(false);
      axios({
        method: "Get",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/market-prices",
        withCredentials: true,
        params: {
          offset: params.offset,
          pageSize: params.pageSize,
          baseAssetIds:
            selectedFilter && selectedFilter.baseAssetId
              ? JSON.stringify(selectedFilter.baseAssetId)
              : undefined,
          quoteAssetIds:
            selectedFilter && selectedFilter.quoteAssetId
              ? JSON.stringify(selectedFilter.quoteAssetId)
              : undefined,
          venueIds:
            selectedFilter && selectedFilter.venueIds
              ? JSON.stringify(selectedFilter.venueIds)
              : undefined,
        },
      })
        .then((res) => {
          if (params.offset === 0) {
            setPriceCount(res.data.count);
          }
          setTableData(res.data.prices);
        })
        .finally(() => {
          setOnFinishLoad(true);
        });
    },
    [selectedFilter],
  );
  const downloadCSV = useCallback(() => {
    axios({
      method: "POST",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/market-prices/export-csv",
      data: {
        baseAssetIds:
          selectedFilter && selectedFilter.baseAssetId
            ? selectedFilter.baseAssetId
            : undefined,
        quoteAssetIds:
          selectedFilter && selectedFilter.quoteAssetId
            ? selectedFilter.quoteAssetId
            : undefined,
        venueIds:
          selectedFilter && selectedFilter.venueIds
            ? selectedFilter.venueIds
            : undefined,
      },
      withCredentials: true,
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let date = new Date();

        link.setAttribute(
          "download",
          `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
            2,
            "0",
          )}${String(date.getDate()).padStart(2, "0")}_venue_market_price.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        } else if (err.response.status === 408) {
          toast.error(err.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  }, [selectedFilter, navigate]);

  const onPaginationChange = (current: any, pageSize: any) => {
    const offset =
      current === 1 || current === 0 ? 0 : (current - 1) * pageSize;
    setCurrentPage(current);
    setCurrPageSize(pageSize);
    fetchData({
      offset: offset,
      pageSize: pageSize,
    });
  };
  const initialColumns = [
    {
      title: "Opening Date Time",
      dataIndex: "ODT",
      width: 100,
      render: (_: any, { value }: any) => (
        <Space>
          {new Date(value.ODT).toLocaleString("en-US", {
            timeZone: displayContext?.displayContext.timezone,
            timeZoneName: "short",
          })}
        </Space>
      ),
    },
    {
      title: "Closing Date Time",
      dataIndex: "CDT",
      width: 100,
      render: (_: any, { value }: any) => (
        <Space>
          {new Date(value.CDT).toLocaleString("en-US", {
            timeZone: displayContext?.displayContext.timezone,
            timeZoneName: "short",
          })}
        </Space>
      ),
    },
    {
      title: "Venue",
      dataIndex: "venueName",
      width: 50,
    },
    {
      title: "Symbol Pair",
      dataIndex: "symbolPair",
      width: 60,
    },
    {
      title: "Opening Price",
      dataIndex: "OP",
      width: 60,
      align: "right" as const,
      render: (_: any, { value }: any) => (
        <Space>
          {value.OP.toLocaleString("en-us", {
            minimumFractionDigits: 4,
            maximumFractionDigits: 9,
          })}
        </Space>
      ),
    },
    {
      title: "Closing Price",
      dataIndex: "CP",
      width: 60,
      align: "right" as const,
      render: (_: any, { value }: any) => (
        <Space>
          {value.CP.toLocaleString("en-us", {
            minimumFractionDigits: 4,
            maximumFractionDigits: 9,
          })}
        </Space>
      ),
    },
    {
      title: "High Price",
      dataIndex: "HP",
      width: 60,
      align: "right" as const,
      render: (_: any, { value }: any) => (
        <Space>
          {value.HP.toLocaleString("en-us", {
            minimumFractionDigits: 4,
            maximumFractionDigits: 9,
          })}
        </Space>
      ),
    },
    {
      title: "Low Price",
      dataIndex: "LP",
      width: 60,
      align: "right" as const,
      render: (_: any, { value }: any) => (
        <Space>
          {value.LP.toLocaleString("en-us", {
            minimumFractionDigits: 4,
            maximumFractionDigits: 9,
          })}
        </Space>
      ),
    },
    {
      title: "VWAP",
      dataIndex: "VP",
      width: 60,
      align: "right" as const,
      render: (_: any, { value }: any) => (
        <Space>
          {value.VP
            ? value.VP.toLocaleString("en-us", {
                minimumFractionDigits: 4,
                maximumFractionDigits: 9,
              })
            : "0.0000"}
        </Space>
      ),
    },
    {
      title: "TWAP",
      dataIndex: "TP",
      width: 60,
      align: "right" as const,
      render: (_: any, { value }: any) => (
        <Space>
          {value.TP.toLocaleString("en-us", {
            minimumFractionDigits: 4,
            maximumFractionDigits: 9,
          })}
        </Space>
      ),
    },
    {
      title: "Trading Volume",
      dataIndex: "TV",
      width: 60,
      fixed: "right" as const,
      align: "right" as const,
      render: (_: any, { value }: any) => (
        <Space>
          {value.TV.toLocaleString("en-us", {
            minimumFractionDigits: 4,
            maximumFractionDigits: 9,
          })}
        </Space>
      ),
    },
  ];
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <Content id="view-venue-market-prices">
      <Row className="dcl-filter-row">
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Row className="dcl-filter-row" align="middle">
            <Space>
              <Button
                className="dcl-btn-toggle"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
              >
                <FontAwesomeIcon icon={"fa-solid fa-filter" as IconProp} />
                Filters
                {showFilter ? (
                  <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
                ) : (
                  <FontAwesomeIcon
                    icon={"fa-solid fa-caret-down" as IconProp}
                  />
                )}
              </Button>
            </Space>
          </Row>
          <Form
            title="Filter"
            form={filterForm}
            hidden={!showFilter}
            className="dcl-toggled-content dcl-toggled-content-filter"
          >
            <Row>
              <Form.Item name="baseAssetId" className="dcl-filter-item">
                <Select
                  className="dcl-asset-select"
                  mode="multiple"
                  allowClear
                  placeholder="Base Asset"
                  options={baseAssetOptions}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item name="quoteAssetId" className="dcl-filter-item">
                <Select
                  className="dcl-asset-select"
                  mode="multiple"
                  allowClear
                  placeholder="Quote Asset"
                  options={quoteAssetOptions}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item name="venueIds" className="dcl-filter-item">
                <Select
                  className="dcl-venue-select"
                  mode="multiple"
                  allowClear
                  placeholder="Venues"
                  options={venueList}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
            </Row>
            <Row justify={"end"}>
              <Button
                type="primary"
                onClick={() => applyFilter(filterForm.getFieldsValue())}
              >
                Apply
              </Button>
            </Row>
          </Form>
        </Col>
        <Col md={{ span: 8, order: 1 }} sm={{ span: 8, order: 1 }}>
          <Row justify="end">
            <Button
              className="dcl-btn-toggle"
              style={{ marginRight: "10px" }}
              onClick={downloadCSV}
              // disabled
            >
              <FontAwesomeIcon icon={"fa-solid fa-file-csv" as IconProp} />
              CSV Export
            </Button>
          </Row>
        </Col>
      </Row>
      <Table
        sticky
        loading={!onFinishLoad}
        rowKey="id"
        columns={initialColumns}
        dataSource={tableData}
        size={"small"}
        scroll={{
          x: 2000,
        }}
        // onChange={handleTableChange}
        showSorterTooltip={false}
        pagination={{ pageSize: currPageSize, hideOnSinglePage: true }}
      />
      <Row justify="end" className="pagination-row">
        <Pagination
          showSizeChanger
          showQuickJumper
          pageSizeOptions={[100, 200, 300]}
          onChange={onPaginationChange}
          current={currentPage}
          pageSize={currPageSize}
          total={priceCount}
        />
      </Row>
    </Content>
  );
}
