import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";

function ViewVenueAccounts() {
  const [, copy] = useCopyToClipboard();

  const navigate = useNavigate();
  const [venueAccountTypeList, setVenueAccountTypeList] = useState<any>([]);
  const [venueAcccountList, setVenueAccountList] = useState<any>([]);
  const [venueOptions, setVenueOptions] = useState<any[]>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [venueAccountEditing, setVenueAccountEditing] =
    useState<any>(undefined);
  const [onFinishLoaded, setOnFinishLoaded] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");
  const [canEdit, setCanEdit] = useState<boolean>();

  const fetchVenueOptions = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/",
      withCredentials: true,
    })
      .then((res) => {
        setVenueOptions(res.data.list);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);

  const fetchVenueAccounts = useCallback(() => {
    setOnFinishLoaded(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/venue-account-types",
      withCredentials: true,
    })
      .then((res) => {
        const accountTypeList = res.data.venueAccountTypes.map((row: any) => ({
          ...row,
          key: row.id,
          label: row.name,
          value: row.id,
        }));
        setVenueAccountTypeList(accountTypeList);
        axios({
          method: "Get",
          url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/venue-accounts",
          withCredentials: true,
        })
          .then((res) => {
            const data = res.data.venueAccountList.map((row: any) => ({
              ...row,
              key: row.id,
            }));
            setVenueAccountList(data);
            setCanEdit(res.data.canUpdateVenueAccounts);
          })
          .catch((err) => {
            if (err.response.status === 403) {
              navigate("/login");
              return;
            }
          });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
          return;
        }
      })
      .finally(() => {
        setOnFinishLoaded(true);
      });
  }, [navigate]);

  useEffect(() => {
    fetchVenueOptions();
    fetchVenueAccounts();
  }, [fetchVenueOptions, fetchVenueAccounts]);
  const handleCancel = () => {
    setShowModal(false);
    setModalType("");
    setVenueAccountEditing(undefined);
  };
  const handleOk = useCallback(() => {
    switch (modalType) {
      case "create":
        axios({
          method: "Post",
          url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/venue-accounts",
          withCredentials: true,
          data: { ...venueAccountEditing },
        })
          .then(() => {
            toast.success("Venue Account created successfully");
            setShowModal(false);
            setModalType("");
            setVenueAccountEditing(undefined);
            fetchVenueAccounts();
          })
          .catch((err) => {
            console.error(err);
            toast.error("Failed to create new venue acocunt");
          });
        break;
      case "edit":
        axios({
          method: "Put",
          url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/venue-accounts",
          withCredentials: true,
          data: { ...venueAccountEditing },
        })
          .then(() => {
            toast.success("Venue Account updated successfully");
            setShowModal(false);
            setModalType("");
            setVenueAccountEditing(undefined);
            fetchVenueAccounts();
          })
          .catch((err) => {
            toast.error("Failed to update venue acocunt");
            console.error(err);
          });
        break;
      default:
        return;
    }
    return;
  }, [modalType, venueAccountEditing, fetchVenueAccounts]);
  return (
    <Content id="view-venue-accounts">
      <Row
        style={{
          margin: "20px",
        }}
        justify="end"
      >
        <Button
          type="primary"
          onClick={() => {
            setShowModal(true);
            setModalType("create");
          }}
        >
          <FontAwesomeIcon
            icon={"fa-solid fa-plus" as IconProp}
            className="white-plus"
          />
          &nbsp; New Account
        </Button>
      </Row>
      {onFinishLoaded ? (
        <Table
          sticky
          scroll={{ x: 1200 }}
          dataSource={venueAcccountList}
          columns={[
            {
              title: "Venue",
              dataIndex: "venueId",
              render: (_: any, { venueId }: any) => (
                <>
                  {venueOptions &&
                  venueOptions.length &&
                  venueOptions.find((row) => row.id === venueId)
                    ? venueOptions.find((row) => row.id === venueId).name
                    : ""}
                </>
              ),
              width: "100px",
            },
            {
              title: "Venue Account Type",
              dataIndex: "venueAccountTypeId",
              render: (_: any, { venueAccountTypeId }: any) => (
                <>
                  {venueAccountTypeList &&
                  venueAccountTypeList.length &&
                  venueAccountTypeList.find(
                    (row: any) => row.id === venueAccountTypeId,
                  )
                    ? venueAccountTypeList.find(
                        (row: any) => row.id === venueAccountTypeId,
                      ).name
                    : ""}
                </>
              ),
              width: "180px",
            },
            {
              title: "Label",
              dataIndex: "label",
            },
            {
              title: "Account Number",
              dataIndex: "accountNumber",
            },
            {
              title: "Email",
              dataIndex: "email",
            },
            {
              title: "Username",
              dataIndex: "username",
            },
            {
              title: "Public Key",
              dataIndex: "publicKey",
              render: (_: any, { publicKey }: any) => (
                <>
                  {publicKey}
                  <Button
                    size="small"
                    onClick={() => {
                      copy(publicKey);
                      toast.success(`Copied to clipboard!`);
                    }}
                    style={{
                      border: "none",
                      background: "none",
                      boxShadow: "none",
                    }}
                  >
                    <FontAwesomeIcon icon={"fa-regular fa-copy" as IconProp} />
                  </Button>
                </>
              ),
            },
            {
              title: "Edit",
              dataIndex: "id",
              render: (_: any, row: any) => (
                <Tooltip title="Edit">
                  <Button
                    disabled={showModal || !canEdit}
                    style={{
                      border: "none",
                      background: "none",
                      boxShadow: "none",
                    }}
                    onClick={() => {
                      setShowModal(true);
                      setModalType("edit");
                      setVenueAccountEditing(row);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={"fa-regular fa-pen-to-square" as IconProp}
                      style={{ fontSize: "1.2em" }}
                    />
                  </Button>
                </Tooltip>
              ),
              width: "100px",
            },
          ]}
        />
      ) : (
        <Spin />
      )}

      <Modal
        open={showModal}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="save" type="primary" onClick={handleOk}>
            {modalType === "edit" ? "Save" : "Create"}
          </Button>,
        ]}
        closeIcon={false}
        destroyOnClose
      >
        <Form colon={false} labelCol={{ span: 7 }}>
          <Form.Item label={<b className="modal-form-label">Venue</b>}>
            <Select
              options={venueOptions}
              defaultValue={venueAccountEditing?.venueId}
              showSearch
              filterOption={(input, option: any) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(e) => {
                setVenueAccountEditing({ ...venueAccountEditing, venueId: e });
              }}
            />
          </Form.Item>
          <Form.Item label={<b className="modal-form-label">Account Type</b>}>
            <Select
              options={venueAccountTypeList}
              defaultValue={venueAccountEditing?.venueAccountTypeId}
              showSearch
              filterOption={(input, option: any) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(e) => {
                setVenueAccountEditing({
                  ...venueAccountEditing,
                  venueAccountTypeId: e,
                });
              }}
            />
          </Form.Item>
          <Form.Item label={<b className="modal-form-label">Label</b>}>
            <Input
              defaultValue={venueAccountEditing?.label}
              onChange={(e) => {
                setVenueAccountEditing({
                  ...venueAccountEditing,
                  label: e.target.value,
                });
              }}
            />
          </Form.Item>
          <Form.Item label={<b className="modal-form-label">Account Number</b>}>
            <Input
              defaultValue={venueAccountEditing?.accountNumber}
              onChange={(e) => {
                setVenueAccountEditing({
                  ...venueAccountEditing,
                  accountNumber: e.target.value,
                });
              }}
            />
          </Form.Item>
          <Form.Item label={<b className="modal-form-label">Username</b>}>
            <Input
              defaultValue={venueAccountEditing?.username}
              onChange={(e) => {
                setVenueAccountEditing({
                  ...venueAccountEditing,
                  username: e.target.value,
                });
              }}
            />
          </Form.Item>
          <Form.Item label={<b className="modal-form-label">Email</b>}>
            <Input
              defaultValue={venueAccountEditing?.email}
              onChange={(e) => {
                setVenueAccountEditing({
                  ...venueAccountEditing,
                  email: e.target.value,
                });
              }}
            />
          </Form.Item>
          <Form.Item label={<b className="modal-form-label">Public Key</b>}>
            <TextArea
              defaultValue={venueAccountEditing?.publicKey}
              rows={5}
              onChange={(e) => {
                setVenueAccountEditing({
                  ...venueAccountEditing,
                  publicKey: e.target.value,
                });
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Content>
  );
}
export default ViewVenueAccounts;
