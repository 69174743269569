import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
} from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DisplayContext } from "../../../Context/displayContext";
import AssignOperation from "../../OperationPages/AssignOperation";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";
import ViewOperationSummary from "../../OperationPages/ViewOperationSummary";
import CreateVirtualLedger from "../CreateVirtualLedger";

dayjs.extend(timezone);
const { RangePicker } = DatePicker;

const operationStatusOptions = [
  { key: "all", label: "All", value: "all" },
  { key: "assigned", label: "Assigned", value: "not_null" },
  { key: "closed", label: "Closed", value: "closed" },
  { key: "none", label: "Not Assigned", value: "none" },
  { key: "open", label: "Open", value: "open" },
  { key: "partially_open", label: "Partially Open", value: "partially_open" },
];

export default function ViewVenueLedgers({ tabName }: any) {
  const [, copy] = useCopyToClipboard();

  const copyMessageToClipboard = (messageToCopy: string) => {
    copy(messageToCopy);
    toast.success(`${messageToCopy} has been copied to clipboard`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const navigate = useNavigate();
  const displayContext = useContext(DisplayContext);
  const location = useLocation();
  const [onFinishLoad, setOnFinishLoad] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currPageSize, setCurrPageSize] = useState(100);
  const [venueLedgers, setVenueLedgers] = useState<any[]>([]);
  const [ledgerCount, setLedgerCount] = useState();

  const [filterForm] = Form.useForm();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [assetOptions, setAssetOptions] = useState<any[]>();
  const [venueList, setVenueList] = useState<any[]>();
  const [transactionWayList, setTransactionWayList] = useState<any[]>();

  const [sortField, setSortField] = useState("timestamp");
  const [sortOrder, setSortOrder] = useState("descend");

  const [fetchDate, setFetchDate] = useState<any>();
  const [isRunning, setIsRunning] = useState<boolean>(false);

  const [venueAccountList, setVenueAccountList] = useState<any>();
  const [selectedVenues, setSelectedVenues] = useState<any>([]);
  const [venueAccountDropdownList, setVenueAccountDropdownList] = useState<any>(
    [],
  );

  const [showOperationAssignModal, setShowOperationAssignModal] =
    useState<boolean>(false);
  const [referenceId, setReferenceId] = useState<number>();
  const [referenceType, setReferenceType] = useState<string>();
  const [referenceItem, setReferenceItem] = useState<any>();

  const [clientOptions, setClientOptions] = useState<any[]>();
  const [canCreateVirtualLedger, setCanCreateVirtualLedger] =
    useState<boolean>(false);
  const [showCreateVirtualLedger, setShowCreateVirtualLedger] =
    useState<boolean>(false);
  const fetchCanCreateVirtualLedger = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/can-create-virtural-ledger",
      withCredentials: true,
    }).then((res) => {
      setCanCreateVirtualLedger(res.data.canCreateVirtualLedger);
    });
  }, []);
  useEffect(() => {
    fetchCanCreateVirtualLedger();
  }, [fetchCanCreateVirtualLedger]);

  const getClientOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-list",
      params: {
        withOperationOpen: JSON.stringify(true),
      },
      withCredentials: true,
    })
      .then((res) => {
        for (const c of res.data.clients) {
          c.label = `${c.label} (${c.deltecAccount})`;
        }
        setClientOptions(res.data.clients);
      })
      .catch(() => {
        console.log("Unable to fetch");
      });
  }, []);

  const getVenueAccountList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/venue-accounts",
      withCredentials: true,
    })
      .then((res) => {
        const data = res.data.venueAccountList.map((row: any) => ({
          key: row.id,
          venueId: row.venueId,
          label: `${row.label}${
            row.accountNumber ? ` (${row.accountNumber})` : ""
          }`,
          value: row.id,
        }));
        setVenueAccountList(data);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
          return;
        }
      });
  }, [navigate]);

  const getAssetList = useCallback((params = {}) => {
    setOnFinishLoad(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/select-list",
      withCredentials: true,
    })
      .then((res) => {
        const options = [];
        for (const asset of res.data.assetList) {
          options.push({
            label: `${asset.name} (${asset.ticker})`,
            value: asset.id,
          });
        }
        setAssetOptions(options);
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  }, []);

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days after today and today
    return current > dayjs().endOf("day");
  };

  const fetchData = useCallback(
    (
      params = {
        offset: 0,
        pageSize: 100,
        venues: undefined,
        tradeId: undefined,
        ledgerId: undefined,
        transactionWay: undefined,
        assetNames: undefined,
        startDate: undefined,
        endDate: undefined,
        isOTC: undefined,
        venueAccountIds: undefined,
        operationStatus: undefined,
        sortField: sortField,
        sortOrder: sortOrder,
      },
    ) => {
      setOnFinishLoad(false);
      setIsRunning(true);
      axios({
        method: "GET",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/venue-ledgers",
        withCredentials: true,
        params: {
          offset: params.offset,
          pageSize: params.pageSize,
          venues: JSON.stringify(params.venues),
          ledgerId: params.ledgerId,
          tradeId: params.tradeId,
          startDate: params.startDate,
          endDate: params.endDate,
          assetNames: JSON.stringify(params.assetNames),
          transactionWayIds: JSON.stringify(params.transactionWay),
          venueAccountIds: JSON.stringify(params.venueAccountIds),
          isOTC: params.isOTC,
          sortField: params.sortField,
          sortOrder: params.sortOrder,
          operationStatus: params.operationStatus,
        },
      })
        .then((res) => {
          setVenueLedgers(res.data.ledgers);
          if (params.offset === 0) {
            setLedgerCount(res.data.count);
            setFetchDate(new Date(res.data.lastUpdated));
            setIsRunning(res.data.isRunning);
          }
        })
        .finally(() => {
          setOnFinishLoad(true);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const instantFetch = useCallback(() => {
    setOnFinishLoad(false);
    setIsRunning(true);
    axios({
      method: "POST",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/venue-transaction/instant",
      withCredentials: true,
    })
      .then((res) => {
        setFetchDate(new Date(res.data.lastUpdated));
      })
      .finally(() => {
        setCurrentPage(0);
        let startDate, endDate;
        if (filterForm.getFieldValue("dateRange")) {
          startDate = filterForm
            .getFieldValue("dateRange")[0]
            .tz("America/New_York", true)
            .format();
          endDate = filterForm
            .getFieldValue("dateRange")[1]
            .tz("America/New_York", true)
            .endOf("day")
            .format();
        }
        fetchData({
          offset: 0,
          pageSize: currPageSize,
          venues: undefined,
          ledgerId: undefined,
          tradeId: undefined,
          assetNames: undefined,
          transactionWay: filterForm.getFieldValue("transactionWayIds")
            ? filterForm.getFieldValue("transactionWayIds")
            : [1, 2],
          venueAccountIds: filterForm.getFieldValue("venueAccountIds"),
          startDate: filterForm.getFieldValue("dateRange")
            ? startDate
            : undefined,
          endDate: filterForm.getFieldValue("dateRange") ? endDate : undefined,
          isOTC: filterForm.getFieldValue("isOTC"),
          operationStatus: undefined,
          sortField: sortField,
          sortOrder: sortOrder,
        });
      });
  }, [fetchData, currPageSize, sortField, filterForm, sortOrder]);

  const [showOperationDetailModal, setShowOperationDetailModal] =
    useState<boolean>(false);

  const initialColumns = [
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      hidden: false,
      sorter: true,
      editable: false,
      defaultSortOrder: "descend" as const,
      render: (_: any, { timestamp }: any) => (
        <Space>
          {new Date(timestamp).toLocaleString("en-US", {
            timeZone: displayContext?.displayContext.timezone,
            timeZoneName: "short",
          })}
        </Space>
      ),
    },
    {
      title: "Operation",
      dataIndex: "operation",
      hidden: false,
      sorter: false,
      editable: false,
      align: "center" as const,
      render: (
        _: any,
        {
          id,
          operationItem,
          amount,
          assetId,
          assetType,
          baseCurrencyName,
          transactionWayName,
          feeAmount,
          feeCurrencyAssetId,
          feeCurrencyName,
          txHash,
          walletAddress,
        }: any,
      ) =>
        operationItem && operationItem.length ? (
          <Row justify={"center"}>
            <Button
              onClick={() => {
                setReferenceId(id);
                setReferenceType("venue_ledger");
                setShowOperationDetailModal(true);
              }}
              style={{
                border: "none",
                background: "none",
                boxShadow: "none",
              }}
            >
              <FontAwesomeIcon
                icon={
                  operationItem?.some(
                    (item: any) =>
                      item.operation?.operationStatus?.name?.toLocaleLowerCase() ===
                      "open",
                  )
                    ? ("fa-solid fa-lock-open" as IconProp)
                    : ("fa-solid fa-lock" as IconProp)
                }
                className={
                  operationItem?.some(
                    (item: any) =>
                      item.operation?.operationStatus?.name?.toLocaleLowerCase() ===
                      "open",
                  )
                    ? operationItem?.every(
                        (item: any) =>
                          item.operation?.operationStatus?.name?.toLocaleLowerCase() ===
                          "open",
                      )
                      ? "all-open"
                      : "some-open"
                    : "closed"
                }
              />
            </Button>
          </Row>
        ) : (
          <Row justify={"center"}>
            <Button
              onClick={() => {
                setReferenceId(id);
                setReferenceType("venue_ledger");
                setShowOperationAssignModal(true);
                setReferenceItem({
                  amount: amount,
                  assetId: assetId,
                  assetName: baseCurrencyName,
                  fee: {
                    amount: feeAmount,
                    assetId: feeCurrencyAssetId ? feeCurrencyAssetId : assetId,
                    assetName: feeCurrencyName
                      ? feeCurrencyName
                      : baseCurrencyName,
                  },
                  transactionWay: transactionWayName.toLocaleLowerCase(),
                  assetType: assetType,
                  walletAddress,
                  txHash,
                });
              }}
              style={{
                border: "none",
                background: "none",
                boxShadow: "none",
              }}
              disabled={!canAssignOperation}
            >
              {canAssignOperation ? (
                <FontAwesomeIcon
                  icon={"fa-regular fa-square-plus" as IconProp}
                  style={{ fontSize: "1.2em" }}
                />
              ) : (
                <></>
              )}
            </Button>
          </Row>
        ),
      width: "100px",
    },
    {
      title: "Ledger ID",
      dataIndex: "ledgerId",
      hidden: false,
      sorter: false,
      editable: false,
      render: (_: any, { ledgerId, info }: any) => (
        <React.Fragment>
          <Button
            onClick={() => copyMessageToClipboard(ledgerId)}
            size={"small"}
            className={"icon-button"}
          >
            <FontAwesomeIcon icon={"fa-regular fa-copy" as IconProp} />
          </Button>
          <Tooltip title={info ? info.replaceAll(/\\/g, "") : ""}>
            <i className="dcl-info">i</i>
          </Tooltip>
          {ledgerId}
        </React.Fragment>
      ),
    },
    {
      title: "Trade ID",
      dataIndex: "tradeId",
      hidden: false,
      sorter: false,
      editable: false,
      render: (_: any, { tradeId, venueTradeId }: any) => (
        <React.Fragment>
          {venueTradeId ? (
            <React.Fragment>
              <Button
                onClick={() => copyMessageToClipboard(tradeId)}
                size={"small"}
                className={"icon-button"}
              >
                <FontAwesomeIcon icon={"fa-regular fa-copy" as IconProp} />
              </Button>
              <Link
                to={"/bo/venues/delchain_transactions/trades"}
                state={{
                  from: window.location.pathname,
                  tradeId: tradeId,
                }}
              >
                {tradeId}
              </Link>
            </React.Fragment>
          ) : (
            tradeId
          )}
        </React.Fragment>
      ),
    },
    {
      title: "Venue",
      dataIndex: "venueName",
      hidden: false,
      sorter: false,
      editable: false,
      width: "75px",
    },
    {
      title: "Venue Account",
      dataIndex: "venueAccountLabel",
      hidden: false,
      sorter: false,
      editable: false,
      width: "200px",
    },
    {
      title: "Order Status",
      dataIndex: "orderStatus",
      hidden: false,
      sorter: false,
      editable: false,
      render: (_: any, { orderStatus }: any) =>
        orderStatus ? orderStatus.name : "",
      width: "100px",
    },
    {
      title: "Is OTC",
      dataIndex: "isOTC",
      hidden: false,
      sorter: false,
      editable: false,
      render: (_: any, { isOTC }: any) => (
        <Checkbox disabled defaultChecked={isOTC} />
      ),
      width: "75px",
    },
    {
      title: "Transaction Way",
      dataIndex: "transactionWayName",
      hidden: false,
      sorter: false,
      editable: false,
      width: "135px",
      render: (_: any, { id, transactionWayName }: any) => (
        <React.Fragment>
          {transactionWayName?.startsWith("Virtual") ? (
            <React.Fragment>
              {transactionWayName} &nbsp;
              <Button
                style={{
                  padding: "1px",
                  margin: "1px",
                  border: "none",
                  background: "none",
                  boxShadow: "none",
                }}
                onClick={() => {
                  setShowDeleteVirtualLedgerModal(true);
                  setDeleteVirtualLedgerId(id);
                }}
              >
                <FontAwesomeIcon icon={"fa-regular fa-trash-can" as IconProp} />
              </Button>
            </React.Fragment>
          ) : (
            transactionWayName
          )}
        </React.Fragment>
      ),
    },
    {
      title: "Transaction Type",
      dataIndex: "transactionTypeName",
      hidden: false,
      sorter: false,
      editable: false,
      width: "125px",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "right" as const,
      hidden: false,
      sorter: false,
      editable: false,
      render: (_: any, { amount }: any) =>
        !isNaN(amount) ? (
          <Space>
            {amount.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 9,
            })}
          </Space>
        ) : (
          <Space></Space>
        ),
      width: "150px",
    },
    {
      title: "Asset",
      dataIndex: "baseCurrencyName",
      hidden: false,
      sorter: false,
      editable: false,
      width: "100px",
    },
    {
      title: "Fee Currency Amount",
      dataIndex: "feeAmount",
      align: "right" as const,
      hidden: false,
      sorter: false,
      editable: false,
      render: (_: any, { feeAmount }: any) =>
        !isNaN(feeAmount) ? (
          <Space>
            {Number(feeAmount).toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 9,
            })}
          </Space>
        ) : (
          <Space>{feeAmount}</Space>
        ),
      width: "150px",
    },
    {
      title: "Fee Currency",
      dataIndex: "feeCurrencyName",
      hidden: false,
      sorter: false,
      editable: false,
      width: "100px",
    },
  ];

  const handleTableChange = (_: any, __: any, sorter: any) => {
    let startDate, endDate;
    if (filterForm.getFieldValue("dateRange")) {
      startDate = filterForm
        .getFieldValue("dateRange")[0]
        .tz("America/New_York", true)
        .startOf("day")
        .format();
      endDate = filterForm
        .getFieldValue("dateRange")[1]
        .tz("America/New_York", true)
        .endOf("day")
        .format();
    }

    setSortOrder(sorter.order);
    setSortField(sorter.field);
    const offset =
      currentPage === 1 || currentPage === 0
        ? 0
        : (currentPage - 1) * currPageSize;
    fetchData({
      offset: offset,
      pageSize: currPageSize,
      venues: filterForm.getFieldValue("venue"),
      ledgerId: filterForm.getFieldValue("ledgerId"),
      tradeId: filterForm.getFieldValue("tradeId"),
      assetNames: filterForm.getFieldValue("assetNames"),
      transactionWay: filterForm.getFieldValue("transactionWayIds"),
      venueAccountIds: filterForm.getFieldValue("venueAccountIds"),
      startDate: filterForm.getFieldValue("dateRange") ? startDate : undefined,
      endDate: filterForm.getFieldValue("dateRange") ? endDate : undefined,
      isOTC: filterForm.getFieldValue("isOTC"),
      operationStatus: filterForm.getFieldValue("operationStatus"),
      sortField: sorter.field,
      sortOrder: sorter.order,
    });
  };

  const onPaginationChange = (current: any, pageSize: any) => {
    let startDate, endDate;
    if (filterForm.getFieldValue("dateRange")) {
      startDate = filterForm
        .getFieldValue("dateRange")[0]
        .tz("America/New_York", true)
        .startOf("day")
        .toISOString();
      endDate = filterForm
        .getFieldValue("dateRange")[1]
        .tz("America/New_York", true)
        .endOf("day")
        .toISOString();
    }
    setOnFinishLoad(false);
    const offset =
      current === 1 || current === 0 ? 0 : (current - 1) * pageSize;
    setCurrentPage(current);
    setCurrPageSize(pageSize);
    fetchData({
      offset: offset,
      pageSize: pageSize,
      venues: filterForm.getFieldValue("venue"),
      ledgerId: filterForm.getFieldValue("ledgerId"),
      tradeId: filterForm.getFieldValue("tradeId"),
      assetNames: filterForm.getFieldValue("assetNames"),
      transactionWay: filterForm.getFieldValue("transactionWayIds"),
      venueAccountIds: filterForm.getFieldValue("venueAccountIds"),
      startDate: filterForm.getFieldValue("dateRange") ? startDate : undefined,
      endDate: filterForm.getFieldValue("dateRange") ? endDate : undefined,
      isOTC: filterForm.getFieldValue("isOTC"),
      operationStatus: filterForm.getFieldValue("operationStatus"),
      sortField: sortField,
      sortOrder: sortOrder,
    });
  };

  const filterData = useCallback(
    (form: any) => {
      let startDate, endDate;
      if (form.dateRange) {
        startDate = form.dateRange[0]
          .tz("America/New_York", true)
          .startOf("day")
          .toISOString();
        endDate = form.dateRange[1]
          .tz("America/New_York", true)
          .endOf("day")
          .toISOString();
      }
      fetchData({
        offset: 0,
        pageSize: currPageSize,
        venues: form.venue,
        ledgerId: form.ledgerId,
        tradeId: form.tradeId,
        assetNames: form.assetNames,
        transactionWay: form.transactionWayIds,
        venueAccountIds: form.venueAccountIds,
        startDate: form.dateRange ? startDate : undefined,
        endDate: form.dateRange ? endDate : undefined,
        isOTC: form.isOTC,
        operationStatus: form.operationStatus,
        sortField: sortField,
        sortOrder: sortOrder,
      });
      setCurrentPage(1);
    },
    [currPageSize, fetchData, sortField, sortOrder],
  );

  const refreshPage = useCallback(
    (form: any) => {
      const offset =
        currentPage === 1 || currentPage === 0
          ? 0
          : (currentPage - 1) * currPageSize;
      let startDate, endDate;
      if (form.dateRange) {
        startDate = form.dateRange[0]
          .tz("America/New_York", true)
          .startOf("day")
          .toISOString();
        endDate = form.dateRange[1]
          .tz("America/New_York", true)
          .endOf("day")
          .toISOString();
      }
      fetchData({
        offset: offset,
        pageSize: currPageSize,
        venues: form.venue,
        ledgerId: form.ledgerId,
        tradeId: form.tradeId,
        assetNames: form.assetNames,
        transactionWay: form.transactionWayIds,
        venueAccountIds: form.venueAccountIds,
        startDate: form.dateRange ? startDate : undefined,
        endDate: form.dateRange ? endDate : undefined,
        isOTC: form.isOTC,
        operationStatus: form.operationStatus,
        sortField: sortField,
        sortOrder: sortOrder,
      });
    },
    [currPageSize, currentPage, fetchData, sortField, sortOrder],
  );

  const onReset = () => {
    filterForm.resetFields();
    fetchData();
  };
  const fetchUtils = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/",
      withCredentials: true,
    })
      .then((res) => {
        setVenueList(res.data.list);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/transaction-way",
      withCredentials: true,
    }).then((res) => {
      setTransactionWayList(res.data);
    });
    getVenueAccountList();
  }, [navigate, getVenueAccountList]);

  const getIsRunning = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/venue-transaction/is-running",
      withCredentials: true,
    }).then((res: any) => {
      setIsRunning(res.data.isRunning);
    });
  }, []);

  const [canAssignOperation, setCanAssignOperation] = useState<boolean>(false);
  const fetchAssignCapability = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/access",
      withCredentials: true,
    })
      .then((res) => {
        setCanAssignOperation(res.data.canAssign);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);

  useEffect(() => {
    fetchAssignCapability();
  }, [fetchAssignCapability]);

  useEffect(() => {
    getClientOptions();
  }, [getClientOptions]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isRunning) {
        getIsRunning();
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [isRunning, getIsRunning]);

  useEffect(() => {
    fetchUtils();
    getAssetList();
    // fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tabName !== "ledgers") return;
    if (location.state?.ledgerId) {
      filterForm.resetFields();
      filterForm.setFieldValue("ledgerId", location.state?.ledgerId);
      setShowFilter(true);
      filterData({ ledgerId: location.state?.ledgerId });
    } else if (location.state?.tradeId) {
      filterForm.resetFields();
      filterForm.setFieldValue("tradeId", location.state?.tradeId);
      setShowFilter(true);
      filterData({ tradeId: location.state?.tradeId });
    } else {
      filterData(filterForm.getFieldsValue());
    }
  }, [tabName, filterForm, location, filterData]);

  useEffect(() => {
    if (
      venueAccountList &&
      venueAccountList.length &&
      selectedVenues &&
      selectedVenues.length
    ) {
      const filteredAccount = venueAccountList.filter((row: any) =>
        selectedVenues.includes(row.venueId),
      );
      setVenueAccountDropdownList(filteredAccount);
    } else if (venueAccountList && venueAccountList.length) {
      setVenueAccountDropdownList(venueAccountList);
    } else if (
      venueAccountList &&
      venueAccountList.length &&
      !selectedVenues.length
    ) {
      setVenueAccountDropdownList(venueAccountList);
    }
  }, [venueAccountList, selectedVenues]);

  const downloadCSV = () => {
    let startDate, endDate;
    if (filterForm.getFieldValue("dateRange")) {
      startDate = filterForm
        .getFieldValue("dateRange")[0]
        .tz("America/New_York", true)
        .startOf("day")
        .toISOString();
      endDate = filterForm
        .getFieldValue("dateRange")[1]
        .tz("America/New_York", true)
        .endOf("day")
        .toISOString();
    }
    axios({
      method: "POST",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/venue-ledgers/export-csv",
      withCredentials: true,
      data: {
        venues: filterForm.getFieldValue("venue"),
        ledgerId: filterForm.getFieldValue("ledgerId"),
        tradeId: filterForm.getFieldValue("tradeId"),
        assetNames: filterForm.getFieldValue("assetNames"),
        transactionWay: filterForm.getFieldValue("transactionWayIds"),
        isOTC: String(filterForm.getFieldValue("isOTC")),
        venueAccountIds: filterForm.getFieldValue("venueAccountIds"),
        startDate: filterForm.getFieldValue("dateRange")
          ? startDate
          : undefined,
        endDate: filterForm.getFieldValue("dateRange") ? endDate : undefined,
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let date = new Date();

        link.setAttribute(
          "download",
          `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
            2,
            "0",
          )}${String(date.getDate()).padStart(2, "0")}_venue_ledgers.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        } else if (err.response.status === 408) {
          toast.error(err.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const [showDeleteVirtualLedgerModal, setShowDeleteVirtualLedgerModal] =
    useState<boolean>(false);
  const [deleteVirtualLedgerId, setDeleteVirtualLedgerId] = useState<any>();
  const [isDeletingVirtualLedger, setIsDeletingVirtualLedger] =
    useState<boolean>(false);
  const deleteVirtualLedger = useCallback(() => {
    setIsDeletingVirtualLedger(true);
    axios({
      method: "Delete",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/operations/virtual-ledger",
      data: { referenceId: deleteVirtualLedgerId, reference: "venue_ledger" },
      withCredentials: true,
    })
      .then(() => {
        toast.success("Deleted Successfully");
        filterData(filterForm);
      })
      .finally(() => {
        setIsDeletingVirtualLedger(false);
        setShowDeleteVirtualLedgerModal(false);
        setDeleteVirtualLedgerId(undefined);
      });
  }, [filterData, filterForm, deleteVirtualLedgerId]);

  return (
    <Content id="view-venue-ledger">
      <Row className="dcl-filter-row">
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Row className="dcl-filter-row" align="middle">
            <Space>
              <Button
                className="dcl-btn-toggle"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
              >
                <FontAwesomeIcon icon={"fa-solid fa-filter" as IconProp} />
                Filters
                {showFilter ? (
                  <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
                ) : (
                  <FontAwesomeIcon
                    icon={"fa-solid fa-caret-down" as IconProp}
                  />
                )}
              </Button>
            </Space>
            <Space>
              <Button className="ant-btn-primary" onClick={instantFetch}>
                Instant Fetch
              </Button>
            </Space>
            <Space className="dcl-filter-row-space">
              <b>Last updated: </b>
              {fetchDate
                ? fetchDate.toLocaleString("en-US", {
                    timeZone: displayContext?.displayContext.timezone,
                    timeZoneName: "short",
                  })
                : "Pending..."}
              {isRunning ? <Spin /> : <></>}
            </Space>
          </Row>
          <Form
            title="Filter"
            form={filterForm}
            onFinish={filterData}
            className="dcl-toggled-content dcl-toggled-content-filter"
            hidden={!showFilter}
            labelWrap
          >
            <Row>
              <Form.Item name="dateRange" className="dcl-filter-item">
                <RangePicker
                  disabledDate={disabledDate}
                  className="dcl-daterange-select"
                />
              </Form.Item>
              <Form.Item name="venue" className="dcl-filter-item">
                <Select
                  mode="multiple"
                  allowClear
                  className="dcl-venue-select"
                  placeholder="Venues"
                  options={venueList}
                  onChange={(e) => {
                    setSelectedVenues([...e]);
                  }}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item name="venueAccountIds" className="dcl-filter-item">
                <Select
                  className="dcl-order-type-select"
                  mode="multiple"
                  allowClear
                  placeholder="Venue Accounts"
                  options={venueAccountDropdownList}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item name="assetNames" className="dcl-filter-item">
                <Select
                  className="dcl-asset-select"
                  mode="multiple"
                  allowClear
                  showSearch
                  placeholder="Asset Tickers"
                  options={assetOptions}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item name="transactionWayIds" className="dcl-filter-item">
                <Select
                  className="dcl-transaction-way-select"
                  mode="multiple"
                  allowClear
                  placeholder="Transaction Way"
                  options={transactionWayList}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item
                name="ledgerId"
                className="dcl-filter-item"
                normalize={(value) => value.trim()}
              >
                <Input placeholder="Ledger Id" />
              </Form.Item>
              <Form.Item
                name="tradeId"
                className="dcl-filter-item"
                normalize={(value) => value.trim()}
              >
                <Input placeholder="Trade Id" />
              </Form.Item>
              <Form.Item name="isOTC" className="dcl-filter-item">
                <Select
                  className="dcl-order-type-select"
                  placeholder="Is OTC"
                  options={[
                    { label: "All", value: "all", key: "0" },
                    { label: "Yes", value: true, key: "1" },
                    { label: "No", value: false, key: "2" },
                  ]}
                  allowClear
                />
              </Form.Item>
              <Form.Item name="operationStatus" className="dcl-filter-item">
                <Select
                  className="dcl-order-type-select"
                  allowClear
                  placeholder="Operation Status"
                  options={operationStatusOptions}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
            </Row>
            <Row justify="end">
              <Space>
                {ledgerCount}
                <b>Results</b>
              </Space>
              <Space>
                <Button
                  htmlType="submit"
                  className="ant-btn-primary"
                  loading={!onFinishLoad}
                >
                  Apply
                </Button>
                <Button onClick={onReset}>Reset</Button>
              </Space>
            </Row>
          </Form>
        </Col>
        <Col md={{ span: 8, order: 1 }} sm={{ span: 8, order: 1 }}>
          <Row justify="end">
            {canCreateVirtualLedger ? (
              <Button
                type="primary"
                disabled={!canCreateVirtualLedger}
                className="dcl-btn-toggle"
                onClick={() => {
                  setShowCreateVirtualLedger(true);
                }}
              >
                <FontAwesomeIcon
                  icon={"fa-solid fa-plus" as IconProp}
                  className="white-plus"
                />
                Virtual Ledger
              </Button>
            ) : (
              <></>
            )}

            <Button
              className="dcl-btn-toggle"
              style={{ marginRight: "10px" }}
              onClick={downloadCSV}
            >
              <FontAwesomeIcon icon={"fa-solid fa-file-csv" as IconProp} />
              CSV Export
            </Button>
          </Row>
        </Col>
      </Row>
      <Table
        sticky
        onChange={handleTableChange}
        loading={!onFinishLoad}
        rowKey="id"
        columns={initialColumns}
        dataSource={venueLedgers}
        showSorterTooltip={false}
        size={"small"}
        scroll={{
          x: 1800,
        }}
        pagination={{ pageSize: currPageSize, hideOnSinglePage: true }}
        rowClassName={(_, index) => (index % 2 ? "odd" : "even")}
      />
      <Row justify="end" className="pagination-row">
        <Pagination
          showSizeChanger
          showQuickJumper
          pageSizeOptions={[50, 100, 200]}
          onChange={onPaginationChange}
          current={currentPage}
          pageSize={currPageSize}
          total={ledgerCount}
        />
      </Row>

      <Modal
        open={showOperationAssignModal}
        footer={[]}
        onCancel={() => {
          setShowOperationAssignModal(false);
          setClientOptions([...(clientOptions as any)]);
        }}
      >
        <AssignOperation
          clientOptions={clientOptions}
          setShowOperationAssignModal={setShowOperationAssignModal}
          referenceItem={referenceItem}
          referenceId={referenceId}
          referenceType={referenceType}
          fetchData={refreshPage}
          form={filterForm.getFieldsValue()}
        />
      </Modal>
      <Modal
        open={showOperationDetailModal}
        footer={[]}
        closable={true}
        onCancel={() => {
          setReferenceType(undefined);
          setReferenceId(undefined);
          setShowOperationDetailModal(false);
        }}
        destroyOnClose
      >
        <ViewOperationSummary
          clientOptions={clientOptions}
          referenceId={referenceId}
          reference={referenceType}
          setShowModal={setShowOperationDetailModal}
          fetchData={refreshPage}
          form={filterForm.getFieldsValue()}
        />
      </Modal>
      <Modal
        open={showCreateVirtualLedger}
        footer={[]}
        closable={true}
        onCancel={() => {
          setShowCreateVirtualLedger(false);
        }}
        destroyOnClose
      >
        <CreateVirtualLedger
          assetList={assetOptions}
          venueList={venueList}
          setShowCreateVirtualLedger={setShowCreateVirtualLedger}
          fetchData={refreshPage}
          form={filterForm.getFieldsValue()}
        />
      </Modal>
      <Modal
        open={showDeleteVirtualLedgerModal}
        onCancel={() => {
          setShowDeleteVirtualLedgerModal(false);
          setDeleteVirtualLedgerId(undefined);
        }}
        onOk={() => {
          deleteVirtualLedger();
        }}
        cancelButtonProps={{ loading: isDeletingVirtualLedger }}
        okButtonProps={{ loading: isDeletingVirtualLedger }}
      >
        <Alert
          style={{
            marginTop: "30px",
          }}
          message={"Are you sure you want to delete this virtual ledger?"}
          type="warning"
          showIcon
        />
      </Modal>
    </Content>
  );
}
