import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function CreateSubaccountTransfer({
  canCreateSubaccountTransfer,
  setCanCreateSubaccountTransfer,
}: any) {
  const navigate = useNavigate();
  const [assetOptions, setAssetOptions] = useState<any>();
  const [venueList, setVenueList] = useState<any>([]);
  const [venueAccountList, setVenueAccountList] = useState<any>([]);
  const [venueAccountFromList, setVenueAccountFromList] = useState<any>([]);
  const [venueAccountFromId, setVenueAccountFromId] = useState<any>();
  const [venueAccountFromRow, setVenueAccountFromRow] = useState<any>();
  const [venueAccountToList, setVenueAccountToList] = useState<any>([]);
  const [venueAccountToId, setVenueAccountToId] = useState<any>();
  const [venueAccountToRow, setVenueAccountToRow] = useState<any>();
  const [selectedVenueId, setSelectedVenueId] = useState<any>();
  const [transferAmount, setTransferAmount] = useState<any>(undefined);
  const [notes, setNotes] = useState<string>("");
  const [selectedAssetId, setSelectedAssetId] = useState<any>();
  const [venueAccountBalanceList, setVenueAccountBalanceList] = useState<any>(
    [],
  );
  const [selectableAssetOptions, setSelectableAssetOptions] = useState<any>([]);
  const [assetFreeAmount, setAssetFreeAmount] = useState<number>(0);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [isFetchingBalance, setIsFetchingBalance] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [sendingLambdaRequest, setSendingLambdaRequest] =
    useState<boolean>(false);
  // const [lastTransfer, setLastTransfer] = useState<any>(undefined);

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const fetchExistingVenueBalance = useCallback(() => {
    if (!selectedVenueId) return;
    let instantRes = [] as any,
      dailyRes = [] as any,
      instantDate = new Date(0),
      dailyDate = new Date(0);
    axios({
      method: "GET",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/instant-balance-existing",
      withCredentials: true,
    }).then((res) => {
      instantDate = new Date(res.data?.lastDate);
      instantRes = res.data?.data?.map((row: any) => ({
        ticker: row.asset,
        venueAccountId: row.venueAccountId,
        free: row.free,
      }));
      axios({
        method: "GET",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/balance",
        params: {
          offset: 0,
          pageSize: 200,
          venues: selectedVenueId
            ? JSON.stringify([selectedVenueId])
            : undefined,
          sortField: "timestamp",
          sortOrder: "descend",
        },
        withCredentials: true,
      }).then((res) => {
        dailyRes = res.data?.balanceList?.map((row: any) => ({
          ticker: row.asset?.ticker,
          venueAccountId: row.venueAccountId,
          free: row.freeAmount,
        }));
        dailyDate =
          res.data.balanceList && res.data.balanceList.length
            ? new Date(res.data.balanceList[0].timestamp)
            : new Date(0);
        if (instantDate.valueOf() > dailyDate.valueOf() && instantRes.length) {
          console.log("INSTANT");
          setVenueAccountBalanceList(instantRes);
          // Take Daily Balance Result
        } else {
          console.log("Daily");
          setVenueAccountBalanceList(dailyRes);
        }
      });
    });

    // If instant balance has latest data
  }, [selectedVenueId]);

  const fetchData = useCallback(() => {
    setIsFetching(true);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/subaccount-transfer",
      withCredentials: true,
    })
      .then((res) => {
        setCanCreateSubaccountTransfer(res.data.canCreateSubaccountTransfer);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [setCanCreateSubaccountTransfer]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const fetchAssetOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/",
      withCredentials: true,
    })
      .then((res) => {
        const options = [];
        for (const asset of res.data.assetList) {
          options.push({
            label: `${asset.name} (${asset.ticker})`,
            ticker: asset.ticker,
            value: asset.id,
          });
        }
        setAssetOptions(options);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/");
        } else {
          toast.error("Failed to get Venue List");
        }
      });
  }, [navigate]);
  const fetchVenueList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue",
      params: {
        includeAll: true,
      },
      withCredentials: true,
    })
      .then((res) => {
        const listForTransfer = res.data.listForTransfer;
        const venues = res.data.list?.filter((row: any) =>
          listForTransfer.includes(row.value),
        );
        setVenueList(venues);
        if (venues?.length) {
          setSelectedVenueId(venues[0].value);
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/");
        } else {
          toast.error("Failed to get Venue List");
        }
      });
  }, [navigate]);
  const getVenueAccountList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/venue-accounts",
      withCredentials: true,
    })
      .then((res) => {
        const data = res.data.venueAccountList.map((row: any) => ({
          key: row.id,
          venueId: row.venueId,
          label: `${row.label}`,
          accountNumber: row.accountNumber ? `${row.accountNumber}` : "",
          value: row.id,
        }));
        setVenueAccountList(data);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
          return;
        }
      });
  }, [navigate]);
  const resetSelectedAccount = useCallback(() => {
    setVenueAccountFromId(undefined);
    setVenueAccountFromRow(undefined);
    setVenueAccountToId(undefined);
    setVenueAccountToRow(undefined);
    setSelectedAssetId(undefined);
    setTransferAmount(undefined);
    setAssetFreeAmount(0);
    setNotes("");
  }, []);
  const instantBalanceFetch = useCallback(() => {
    setIsFetchingBalance(true);
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/instant-balance",
      withCredentials: true,
    })
      .then((res) => {
        const InstantRes = res.data?.data?.map((row: any) => ({
          ticker: row.asset,
          venueAccountId: row.venueAccountId,
          free: row.free,
        }));
        setVenueAccountBalanceList(InstantRes);
        setIsFetchingBalance(false);

        axios({
          method: "GET",
          url:
            process.env.REACT_APP_AWS_BACKEND_URL +
            "/venue/instant-balance-price",
          withCredentials: true,
        });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
          return;
        }
      });
  }, [navigate]);
  const submitResult = useCallback(() => {
    setSendingLambdaRequest(true);
    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/subaccount-transfer",
      withCredentials: true,
      data: {
        assetId: selectedAssetId,
        venueId: selectedVenueId,
        fromAccount: venueAccountFromId,
        toAccount: venueAccountToId,
        amount: transferAmount,
        notes: notes,
      },
    })
      .then((res) => {
        toast.success("Transfer Request Created");
        toast.success(
          <React.Fragment>
            <Row>
              <b>Transfer ID :</b>
            </Row>
            <Row style={{ marginLeft: "2em" }}>
              {res.data.result?.transfer_id}
            </Row>
            <Row>
              <b>Status:</b>
            </Row>
            <Row style={{ marginLeft: "2em" }}>
              <i>{res.data.result?.status}</i>
            </Row>
          </React.Fragment>,
          {
            autoClose: 10000,
            pauseOnHover: true,
            closeOnClick: false,
          },
        );
        resetSelectedAccount();
      })
      .finally(() => {
        setSendingLambdaRequest(false);
      });
  }, [
    selectedAssetId,
    selectedVenueId,
    venueAccountFromId,
    venueAccountToId,
    transferAmount,
    notes,
    resetSelectedAccount,
  ]);

  useEffect(() => {
    fetchVenueList();
    getVenueAccountList();
    fetchAssetOptions();
  }, [fetchVenueList, getVenueAccountList, fetchAssetOptions]);
  useEffect(() => {
    fetchExistingVenueBalance();
  }, [fetchExistingVenueBalance]);
  useEffect(() => {
    const fromList = venueAccountList.filter(
      (row: any) => row.venueId === selectedVenueId,
    );
    setVenueAccountFromList(fromList);
  }, [selectedVenueId, venueAccountList]);
  useEffect(() => {
    const toList = venueAccountFromList.filter(
      (row: any) => row.value !== venueAccountFromId,
    );
    setVenueAccountToList(toList);
  }, [venueAccountFromId, venueAccountFromList]);
  useEffect(() => {
    if (
      !venueAccountFromId ||
      !venueAccountBalanceList?.length ||
      !assetOptions
    )
      return;
    const filteredBalance = [
      ...new Set(
        venueAccountBalanceList
          .filter((row: any) => row.venueAccountId === venueAccountFromId)
          .map((row: any) => row.ticker),
      ),
    ];
    const assetOptionsFiltered = assetOptions.filter((row: any) =>
      filteredBalance.includes(row.ticker),
    );
    setSelectableAssetOptions(assetOptionsFiltered);
  }, [assetOptions, venueAccountFromId, venueAccountBalanceList]);
  useEffect(() => {
    if (
      !selectedAssetId ||
      !venueAccountBalanceList?.length ||
      !assetOptions ||
      !venueAccountFromId
    )
      return;
    const assetTicker = assetOptions.find(
      (row: any) => row.value === selectedAssetId,
    )?.ticker;
    const venueAccount = venueAccountBalanceList.find(
      (row: any) =>
        row.venueAccountId === venueAccountFromId && row.ticker === assetTicker,
    );
    setAssetFreeAmount(venueAccount.free);
  }, [
    assetOptions,
    selectedAssetId,
    venueAccountFromId,
    venueAccountBalanceList,
  ]);
  useEffect(() => {
    setCanSubmit(
      venueAccountFromId &&
        venueAccountToId &&
        selectedAssetId &&
        assetFreeAmount >= transferAmount &&
        !isFetchingBalance,
    );
  }, [
    assetFreeAmount,
    selectedAssetId,
    transferAmount,
    venueAccountFromId,
    venueAccountToId,
    isFetchingBalance,
  ]);
  return (
    <React.Fragment>
      {isFetching ? (
        <Row justify={"center"}>
          <Spin size="large" />
        </Row>
      ) : !canCreateSubaccountTransfer ? (
        <Row justify={"center"}>
          <h1>Access Denied</h1>
        </Row>
      ) : sendingLambdaRequest ? (
        <Row justify={"center"}>
          <Spin size="large" />
        </Row>
      ) : (
        <Content id="create-subaccount-transfer">
          <Form>
            <Row style={{ margin: "20px" }}>
              <Col xxl={6} xl={8} lg={10} md={12} sm={24}>
                <Form.Item label={<b>Instant Balance Fetch</b>}>
                  <Button
                    className="ant-btn-primary"
                    onClick={instantBalanceFetch}
                    disabled={isFetchingBalance}
                    loading={isFetchingBalance}
                  >
                    Instant Fetch Balance
                  </Button>
                </Form.Item>
                <Form.Item label={<b>Venue</b>}>
                  <Select
                    options={venueList}
                    value={selectedVenueId}
                    onSelect={(e) => {
                      setSelectedVenueId(e);
                      resetSelectedAccount();
                    }}
                    onClear={() => {
                      setSelectedVenueId(undefined);
                      resetSelectedAccount();
                    }}
                    style={{ width: "100%" }}
                    allowClear
                    showSearch
                    filterOption={(input, option: any) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            {selectedVenueId ? (
              <Row justify={"space-between"} style={{ margin: "20px" }}>
                <Col
                  xxl={10}
                  xl={11}
                  lg={11}
                  md={12}
                  sm={24}
                  xs={24}
                  style={{ marginTop: "10px" }}
                >
                  <Card title="From">
                    <Form.Item label={<b>Account</b>}>
                      <Select
                        disabled={!selectedVenueId}
                        options={venueAccountFromList}
                        value={venueAccountFromId}
                        onSelect={(e, option) => {
                          setVenueAccountFromId(e);
                          setVenueAccountFromRow(option);
                          setSelectedAssetId(undefined);
                          setAssetFreeAmount(0);
                        }}
                        onClear={() => {
                          setVenueAccountFromId(undefined);
                          setVenueAccountFromRow(undefined);
                          setSelectedAssetId(undefined);
                          setAssetFreeAmount(0);
                        }}
                        style={{ width: "100%" }}
                        allowClear
                        showSearch
                        filterOption={(input, option: any) => {
                          return option.label
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={<b>Account Number</b>}>
                      {venueAccountFromRow
                        ? venueAccountFromRow.accountNumber
                        : ""}
                    </Form.Item>
                    <Form.Item label={<b>Asset</b>}>
                      <Select
                        disabled={!selectedVenueId || isFetchingBalance}
                        options={selectableAssetOptions}
                        value={selectedAssetId}
                        onSelect={(e) => {
                          setSelectedAssetId(e);
                        }}
                        onClear={() => {
                          setSelectedAssetId(undefined);
                        }}
                        style={{ width: "100%" }}
                        allowClear
                        showSearch
                        filterOption={(input, option: any) => {
                          return option.label
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={<b>Available Amount</b>}>
                      {assetFreeAmount?.toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 8,
                      })}
                    </Form.Item>
                    <Form.Item label={<b>Amount</b>}>
                      <InputNumber
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setTransferAmount(e);
                        }}
                        min={0}
                        max={assetFreeAmount}
                        value={transferAmount}
                        formatter={(value) => {
                          const parts = `${value}`.split(".");
                          // Apply the original regex only to the first part (integer part) to add commas.
                          const integerPartWithCommas = parts[0].replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ",",
                          );
                          // Reassemble the parts, including the decimal part if it was present.
                          return parts.length > 1
                            ? `${integerPartWithCommas}.${parts[1]}`
                            : integerPartWithCommas;
                        }}
                        parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Card>
                </Col>
                <Col
                  xxl={10}
                  xl={11}
                  lg={11}
                  md={12}
                  sm={24}
                  xs={24}
                  style={{ marginTop: "10px" }}
                >
                  <Card title="To">
                    <Form.Item label={<b>Account</b>}>
                      <Select
                        disabled={!selectedVenueId || !venueAccountFromId}
                        options={venueAccountToList}
                        value={venueAccountToId}
                        onSelect={(e, option) => {
                          setVenueAccountToId(e);
                          setVenueAccountToRow(option);
                        }}
                        onClear={() => {
                          setVenueAccountToId(undefined);
                          setVenueAccountToRow(undefined);
                        }}
                        style={{ width: "100%" }}
                        allowClear
                        showSearch
                        filterOption={(input, option: any) => {
                          return option.label
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={<b>Account Number</b>}>
                      {venueAccountToRow ? venueAccountToRow.accountNumber : ""}
                    </Form.Item>
                  </Card>
                  <Card title="Transfer Notes" style={{ marginTop: "10px" }}>
                    <Form.Item label={<b>Notes</b>}>
                      <TextArea
                        value={notes}
                        onChange={(e) => {
                          setNotes(e.target.value);
                        }}
                        style={{ width: "300px" }}
                        rows={3}
                      />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={24}>
                  <Row justify={"space-around"} style={{ marginTop: "20px" }}>
                    <Button
                      type="primary"
                      style={{ minWidth: "300px" }}
                      disabled={!canSubmit}
                      onClick={() => {
                        setShowConfirmModal(true);
                      }}
                    >
                      <b>Submit</b>
                    </Button>
                  </Row>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </Form>
          <Modal
            open={showConfirmModal}
            width={"25%"}
            closable={false}
            footer={
              <Row justify={"end"}>
                <Button
                  onClick={() => {
                    setShowConfirmModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    setShowConfirmModal(false);
                    submitResult();
                  }}
                >
                  Confirm
                </Button>
              </Row>
            }
          >
            <Alert
              className="creat-subaccount-transfer-modal"
              message={
                <>
                  You are about to transfer
                  <br />
                  <b>
                    {transferAmount?.toLocaleString("en-us", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 8,
                    })}{" "}
                    {assetOptions && assetOptions.length && selectedAssetId
                      ? assetOptions.find(
                          (row: any) => row.value === selectedAssetId,
                        ).ticker
                      : ""}
                  </b>
                  <br />
                  From <b>{venueAccountFromRow?.label} </b>
                  <br />
                  To <b>{venueAccountToRow?.label}</b>
                </>
              }
              type="warning"
            />
          </Modal>
        </Content>
      )}
    </React.Fragment>
  );
}
export default CreateSubaccountTransfer;
