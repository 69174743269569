import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  InputNumber,
  Row,
  Select,
} from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function QuoteBuilderDefaultValues() {
  const navigate = useNavigate();

  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [defaultFeeObj, setDefaultFeeObj] = useState<any>();
  const [dataDisplay, setDataDisplay] = useState<any>();
  const [venueList, setVenueList] = useState<any>();
  const getDefaultFees = useCallback(() => {
    setDataFetched(false);
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/order-builder-default-fees",
      withCredentials: true,
    })
      .then((res) => {
        setDefaultFeeObj(res.data);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setDataFetched(true);
      });
  }, [navigate]);
  useEffect(() => {
    getDefaultFees();
  }, [getDefaultFees]);

  const renderObj = useCallback(() => {
    if (!defaultFeeObj) return;
    const objectArr = Object.entries(defaultFeeObj).filter(
      ([key, value]) => key !== "defaultVenue",
    );
    const obj = objectArr.map(([k1, v1]: any[], idx) => {
      const valueArr = Object.entries(v1);
      return (
        <React.Fragment key={idx}>
          <Divider orientation="left">
            {
              venueList?.find((row: any) => Number(row.value) === Number(k1))
                ?.label
            }
          </Divider>
          <Row justify={"space-between"}>
            {valueArr.map(([k2, v2]: any) => (
              <Col span={4}>
                <Form.Item label={k2}>
                  <InputNumber
                    value={v2}
                    onChange={(e) => {
                      defaultFeeObj[k1][k2] = e;
                    }}
                  />
                </Form.Item>
              </Col>
            ))}
          </Row>
        </React.Fragment>
      );
    });
    setDataDisplay(obj);
  }, [defaultFeeObj, venueList]);
  useEffect(() => {
    renderObj();
  }, [renderObj]);

  const fetchVenueList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue",
      params: {
        includeBank: true,
      },
      withCredentials: true,
    }).then((res) => {
      setVenueList(res.data.list);
    });
  }, []);

  useEffect(() => {
    fetchVenueList();
  }, [fetchVenueList]);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const submitData = useCallback(() => {
    setSubmitting(true);
    axios({
      method: "Put",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/quote-builder-default-fees",
      data: {
        ...defaultFeeObj,
      },
      withCredentials: true,
    })
      .then((res) => {
        toast.success("Updated Successfully");
      })
      .finally(() => {
        setSubmitting(false);
        getDefaultFees();
      });
  }, [getDefaultFees, defaultFeeObj]);
  return (
    <Card
      title={
        <Row>
          <Col span={8}>Quote Builder Default Values</Col>
        </Row>
      }
      bordered={false}
      loading={!dataFetched}
      style={{
        margin: "10px",
        borderRadius: "10px",
      }}
      id="venue-exposure-limit"
    >
      <Form.Item label="Default Venue" key="defalutVenue" name={"defaultVenue"}>
        <Select
          options={venueList}
          loading={!dataFetched}
          onChange={(e) => {
            defaultFeeObj.defaultVenue = e;
          }}
          defaultValue={defaultFeeObj?.defaultVenue}
          value={defaultFeeObj?.defaultVenue}
        />
      </Form.Item>
      {dataDisplay}
      <Form.Item>
        <Button
          onClick={() => {
            submitData();
          }}
          loading={submitting}
          htmlType="submit"
          className="submit-button primary-button"
        >
          Update Quote Builder Default Values
        </Button>
      </Form.Item>
    </Card>
  );
}
