import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import dayjs from "dayjs";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DisplayContext } from "../../Context/displayContext";
import AssignOperation from "../OperationPages/AssignOperation";
import ViewOperationSummary from "../OperationPages/ViewOperationSummary";
const { RangePicker } = DatePicker;

export default function CustodyTransaction({ filter }: any) {
  const location = useLocation();
  const navigate = useNavigate();

  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  const [filterForm] = Form.useForm();

  const [tableData, setTableData] = useState<any[]>();
  const [onFinishLoaded, setOnFinishLoaded] = useState<boolean>(false);
  const [tableLength, setTableLength] = useState<number>(0);

  const [showFilter, setShowFilter] = useState<boolean>(filter ? true : false);
  const [clientOptions, setClientOptions] = useState<any[]>();
  const [clientAccountOptions, setClientAccountOptions] = useState<any[]>();
  const [originClientAccountOptions, setOriginClientAccountOptions] =
    useState<any[]>();
  const [assetOptions, setAssetOptions] = useState<any[]>();

  const [pagination, setPagination] = useState<any>();

  const [displayEditModal, setDisplayEditModal] = useState<boolean>(false);
  const [editingRecord, setEditingRecord] = useState<any>();

  const [displayCreateModal, setDisplayCreateModal] = useState<boolean>(false);

  const [createSelectedClient, setCreateSelectedClient] = useState<any>();
  const [createSelectedClientAccount, setCreateSelectedClientAccount] =
    useState<any>();
  const [createSelectedWallet, setCreateSelectedWallet] = useState<any>();
  const [createSelectedSubWallet, setCreateSelectedSubWallet] = useState<any>();
  const [createInputAmount, setCreateInputAmount] = useState<number>();
  const [createInputFeeAmount, setCreateInputFeeAmount] = useState<number>();
  const [createInputFeeCurrency, setCreateInputFeeCurrency] =
    useState<string>();
  const [createInputHash, setCreateInputHash] = useState<string>();
  const [createInputBlockNumber, setCreateInputBlockNumber] =
    useState<string>();
  const [createInputDescription, setCreateInputDescription] =
    useState<string>();
  const [createInputTimestamp, setCreateInputTimestamp] = useState<string>();
  const [createClientAccountOptions, setCreateClientAccountOptions] =
    useState<any>();
  const [createWalletOptions, setCreateWalletOptions] = useState<any>();
  const [createSubWalletOptions, setCreateSubWalletOptions] = useState<any>();

  const [isDownloadingCSV, setIsDownloadingCSV] = useState<boolean>(false);
  const [showOperationAssignModal, setShowOperationAssignModal] =
    useState<boolean>(false);
  const [referenceId, setReferenceId] = useState<number>();
  const [referenceType, setReferenceType] = useState<string>();
  const [referenceItem, setReferenceItem] = useState<any>();
  const [showOperationDetailModal, setShowOperationDetailModal] =
    useState<boolean>(false);
  const [canAssignOperation, setCanAssignOperation] = useState<boolean>(false);
  const [lastFetchTime, setLastFetchTime] = useState<any>(undefined);
  const fetchAssignCapability = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/access",
      withCredentials: true,
    })
      .then((res) => {
        setCanAssignOperation(res.data.canAssign);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);
  const [opClientOptions, setOpClientOptions] = useState<any[]>();
  const getOperationOpenClientList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-list",
      params: {
        withOperationOpen: JSON.stringify(true),
      },
      withCredentials: true,
    })
      .then((res) => {
        for (const c of res.data.clients) {
          c.label = `${c.label} (${c.deltecAccount})`;
        }
        setOpClientOptions(res.data.clients);
      })
      .catch(() => {
        console.log("Unable to fetch the role list");
      });
  }, []);
  useEffect(() => {
    getOperationOpenClientList();
  }, [getOperationOpenClientList]);
  useEffect(() => {
    fetchAssignCapability();
  }, [fetchAssignCapability]);
  const onReset = () => {
    fetchData();
    filterForm.resetFields();
  };

  const exportCSV = useCallback(() => {
    const form = filterForm.getFieldsValue();
    setIsDownloadingCSV(true);
    axios({
      method: "Post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/custody/transaction-export-csv",
      withCredentials: true,
      data: {
        ...form,
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let date = new Date();

        link.setAttribute(
          "download",
          `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
            2,
            "0",
          )}${String(date.getDate()).padStart(2, "0")}_custody_transaction.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(() => {
        setIsDownloadingCSV(false);
      });
  }, [filterForm]);

  const filterTransactions = (form: any) => {
    axios({
      method: "Post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/custody/transactions-with-filter",
      withCredentials: true,
      data: {
        ...form,
        offset: 0,
        pageSize: pagination ? pagination.pageSize : 10,
      },
    })
      .then((res) => {
        setTableData(res.data.transactions);
        setTableLength(res.data.total);
        setPagination({
          current: 1,
          pageSize: pagination.pageSize,
          total: res.data.total,
        });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setOnFinishLoaded(true);
      });
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current: any) => {
    // Can not select days after today and today
    return current > dayjs().endOf("d");
  };
  const updateClientAccountList = useCallback(() => {
    if (originClientAccountOptions && originClientAccountOptions.length) {
      const newOptions = originClientAccountOptions.filter((t) =>
        filterForm.getFieldValue("clientIds").includes(t.clientId),
      );
      console.log(newOptions);
      setClientAccountOptions(newOptions);
    }
  }, [filterForm, originClientAccountOptions]);

  const getWalletAndSubWallets = useCallback((id: number) => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/wallets-with-client-account-id",
      params: {
        clientAccountId: id,
      },
      withCredentials: true,
    }).then((res: any) => {
      setCreateWalletOptions(res.data.wallets);
    });
  }, []);

  const updateCreateClientAccountList = useCallback(
    (e: any) => {
      setCreateSelectedClient(e);
      setCreateSelectedClientAccount(undefined);
      setCreateSelectedWallet(undefined);
      setCreateSelectedSubWallet(undefined);
      if (originClientAccountOptions && originClientAccountOptions.length) {
        const newOptions = originClientAccountOptions.filter(
          (t) => t.clientId === e,
        );
        setCreateClientAccountOptions(newOptions);
      }
    },
    [originClientAccountOptions],
  );

  const getClientAccountsList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-accounts",
      withCredentials: true,
    })
      .then((res) => {
        setClientAccountOptions(res.data.clientAccounts);
        setOriginClientAccountOptions(res.data.clientAccounts);
      })
      .catch(() => {
        console.log("Unable to fetch the wallet type list");
      });
  }, []);

  const getClientOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-list",
      withCredentials: true,
    })
      .then((res) => {
        res.data.clients.map(
          (t: any) => (t.label = `${t.label} (${t.deltecAccount})`),
        );
        setClientOptions(res.data.clients);
      })
      .catch(() => {
        console.log("Unable to fetch the role list");
      });
  }, []);

  const getAssetOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/select-list",
      withCredentials: true,
    })
      .then((res) => {
        const options = [];
        for (const asset of res.data.assetList) {
          options.push({
            label: `${asset.name} (${asset.ticker})`,
            value: asset.id,
          });
        }
        setAssetOptions(options);
      })
      .catch(() => {
        console.log("Unable to fetch the asset list");
      });
  }, []);

  const fetchSelectOptions = useCallback(() => {
    getAssetOptions();
    getClientOptions();
    getClientAccountsList();
  }, [getAssetOptions, getClientOptions, getClientAccountsList]);

  useEffect(() => {
    fetchSelectOptions();
  }, [fetchSelectOptions]);

  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);

  const fetchData = useCallback(
    (
      pagination: { current: number; pageSize: number } = {
        current: 0,
        pageSize: 10,
      },
    ) => {
      const form = filterForm ? filterForm.getFieldsValue() : undefined;
      setOnFinishLoaded(false);
      axios({
        method: "Post",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/util/custody/transactions-with-filter",
        data: {
          offset: pagination ? pagination.current : 0,
          pageSize: pagination ? pagination.pageSize : 10,
          ...form,
        },
        withCredentials: true,
      })
        .then((res) => {
          setTableData(res.data.transactions);
          setTableLength(res.data.total);
          if (pagination && pagination.current === 0) {
            setPagination({
              current: 1,
              pageSize: pagination.pageSize,
              total: res.data.total,
            });
          }
          setLastFetchTime(res.data.lastTxnCall);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/login");
          }
        })
        .finally(() => {
          setOnFinishLoaded(true);
        });
    },
    [navigate, filterForm],
  );

  const onPaginationChange = useCallback(
    (pagination: { current: number; pageSize: number }) => {
      setPagination(pagination);
      fetchData(pagination);
    },
    [fetchData],
  );

  useEffect(() => {
    if (tableData) return;
    if (location.state) {
      filterForm.setFieldsValue(location.state);
      setShowFilter(true);
      setOnFinishLoaded(false);
      axios({
        method: "Post",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/util/custody/transactions-with-filter",
        withCredentials: true,
        data: {
          offset: pagination ? pagination.current : 0,
          pageSize: pagination ? pagination.pageSize : 10,
          ...location.state,
        },
      })
        .then((res) => {
          setTableData(res.data.transactions);
          setTableLength(res.data.total);
        })
        .finally(() => {
          if (location.state.pagination)
            setPagination(location.state.pagination);
          setOnFinishLoaded(true);
        });
    } else {
      fetchData();
    }
  }, [tableData, location, filterForm, fetchData, pagination]);

  const createTransaction = useCallback(() => {
    console.log(createInputTimestamp);
    axios({
      method: "Post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/custody/create-transaction",
      withCredentials: true,
      data: {
        hash: createInputHash,
        blockNumber: createInputBlockNumber,
        subWalletId: createSelectedSubWallet,
        value: createInputAmount ? createInputAmount : 0,
        description: createInputDescription,
        fee: createInputFeeAmount ? createInputFeeAmount : 0,
        feeCurrencyId: createInputFeeCurrency,
        timestamp: createInputTimestamp
          ? createInputTimestamp
          : dayjs().toISOString(),
      },
    })
      .then(() => {
        setDisplayCreateModal(false);
        setCreateSelectedClient(undefined);
        setCreateSelectedClientAccount(undefined);
        setCreateSelectedWallet(undefined);
        setCreateSelectedSubWallet(undefined);
        setCreateInputAmount(undefined);
        setCreateInputBlockNumber(undefined);
        setCreateInputBlockNumber(undefined);
        setCreateInputDescription(undefined);
        setCreateInputTimestamp(undefined);
        setCreateInputHash(undefined);
        setCreateInputFeeAmount(undefined);
        setCreateInputFeeCurrency(undefined);
        fetchData();
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  }, [
    createInputTimestamp,
    createInputAmount,
    createInputBlockNumber,
    createInputDescription,
    createInputFeeAmount,
    createInputFeeCurrency,
    createInputHash,
    createSelectedSubWallet,
    fetchData,
  ]);

  const updatingEditingRecord = useCallback(
    (value: string, key: string) => {
      const newEditingRecord = { ...editingRecord };
      newEditingRecord[key] = value;
      setEditingRecord(newEditingRecord);
    },
    [editingRecord],
  );

  const submitEditingRecord = useCallback(() => {
    axios({
      method: "Put",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/custody/update-transaction",
      withCredentials: true,
      data: {
        ...editingRecord,
      },
    })
      .then(() => {
        setDisplayEditModal(false);
        setEditingRecord(undefined);
        fetchData();
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  }, [editingRecord, fetchData]);

  const initialColumns = [
    {
      title: "Client Name",
      dataIndex: "clientName",
      width: 200,
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      width: 250,
      render: (_: any, { timestamp }: any) => (
        <Space>
          {new Date(timestamp).toLocaleString("en-US", {
            timeZone: displayContext?.displayContext.timezone,
            timeZoneName: "short",
          })}
        </Space>
      ),
    },
    {
      title: "Operation",
      dataIndex: "operationItem",
      sorter: false,
      render: (
        _: any,
        { id, operationItem, value, subWallet, feeCurrencyId }: any,
      ) =>
        operationItem && operationItem.length ? (
          <Row justify={"center"}>
            <Button
              onClick={() => {
                setReferenceId(id);
                setReferenceType("subwallet_transaction");
                setShowOperationDetailModal(true);
              }}
              style={{
                border: "none",
                background: "none",
                boxShadow: "none",
              }}
            >
              <FontAwesomeIcon
                icon={
                  operationItem?.some(
                    (item: any) =>
                      item.operation?.operationStatus?.name?.toLocaleLowerCase() ===
                      "open",
                  )
                    ? ("fa-solid fa-lock-open" as IconProp)
                    : ("fa-solid fa-lock" as IconProp)
                }
                className={
                  operationItem?.some(
                    (item: any) =>
                      item.operation?.operationStatus?.name?.toLocaleLowerCase() ===
                      "open",
                  )
                    ? operationItem?.every(
                        (item: any) =>
                          item.operation?.operationStatus?.name?.toLocaleLowerCase() ===
                          "open",
                      )
                      ? "all-open"
                      : "some-open"
                    : "closed"
                }
              />
            </Button>
          </Row>
        ) : (
          <Row justify={"center"}>
            <Button
              onClick={() => {
                setReferenceId(id);
                setReferenceType("subwallet_transaction");
                setShowOperationAssignModal(true);
                setReferenceItem({
                  amount: value,
                  assetId: subWallet.assetId,
                  assetName: subWallet.asset.ticker,
                  fee: {
                    amount: 0,
                    assetId: feeCurrencyId,
                  },
                  transactionWay: "",
                });
              }}
              disabled={!canAssignOperation}
              style={{
                border: "none",
                background: "none",
                boxShadow: "none",
              }}
            >
              <FontAwesomeIcon
                icon={"fa-regular fa-square-plus" as IconProp}
                style={{ fontSize: "1.2em" }}
              />
            </Button>
          </Row>
        ),
      width: "100px",
    },
    {
      title: "Subwallet Label",
      dataIndex: "subWalletLabel",
      width: 200,
      render: (_: any, { subWallet }: any) => <Space>{subWallet.label}</Space>,
    },
    {
      title: "Wallet Address",
      dataIndex: "walletAddress",
      width: 200,
      render: (_: any, { walletId, walletAddress }: any) =>
        walletId ? (
          <Link
            to={"/bo/wallet/view/" + encodeURIComponent(walletId)}
            state={{
              from: window.location.pathname,
              clientIds: filterForm.getFieldValue("clientIds"),
              walletAddress: filterForm.getFieldValue("address"),
              assetIds: filterForm.getFieldValue("assetIds"),
              pagination: pagination,
            }}
          >
            {walletAddress}
          </Link>
        ) : (
          walletAddress
        ),
    },
    {
      title: "Client Account",
      dataIndex: "clientAccount",
      width: 200,
      render: (_: any, { subWallet }: any) => (
        <Space>
          {subWallet.wallet && subWallet.wallet.clientAccount
            ? subWallet.wallet.clientAccount.accountNumber
            : ""}
        </Space>
      ),
    },
    {
      title: "Asset Ticker",
      dataIndex: "assetTikcer",
      width: 100,
      render: (_: any, { assetPrice, subWallet }: any) => (
        <Space>
          <Tooltip title={`Price: ${assetPrice}`}>
            {subWallet.asset ? subWallet.asset.ticker : ""}
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Transaction Hash",
      dataIndex: "hash",
      width: 200,
    },
    {
      title: "Block Number",
      dataIndex: "blockNumber",
      width: 150,
    },
    {
      title: "Quantity",
      dataIndex: "value",
      width: 150,
      align: "right" as const,
      render: (_: any, { value }: any) => (
        <Space>
          {Number(value).toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          })}
        </Space>
      ),
    },
    {
      title: "Quantity in USD",
      dataIndex: "usdValue",
      width: 150,
      align: "right" as const,
      render: (_: any, { value, assetPrice }: any) => (
        <Space>
          $
          {(Number(value) * Number(assetPrice)).toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Space>
      ),
    },
    {
      title: "Fee",
      dataIndex: "fee",
      width: 150,
      align: "right" as const,
      render: (_: any, { fee }: any) => (
        <Space>
          {Number(fee).toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          })}
        </Space>
      ),
    },
    {
      title: "Fee Currency",
      dataIndex: "feeCurrency",
      width: 150,
      render: (_: any, { assetFeeCurrency, feeAssetPrice }: any) => (
        <Space>
          <Tooltip title={`Price: ${feeAssetPrice}`}>
            {assetFeeCurrency ? assetFeeCurrency.ticker : ""}
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Fee in USD",
      dataIndex: "feeUsdValue",
      width: 150,
      align: "right" as const,
      render: (_: any, { fee, feeAssetPrice }: any) => (
        <Tooltip title={Number(fee) * Number(feeAssetPrice)}>
          $
          {(Number(fee) * Number(feeAssetPrice)).toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Tooltip>
      ),
    },
    {
      title: "Memo",
      dataIndex: "description",
      width: 250,
      render: (_: any, { description }: any) => (
        <Space>
          <TextArea value={description} disabled />
        </Space>
      ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      width: 100,
      // fixed: "right" as const,
      render: (_: any, row: any) => (
        <Space>
          <Button
            onClick={() => {
              setDisplayEditModal(true);
              setEditingRecord({
                ...row,
                feeTicker: row.assetFeeCurrency
                  ? row.assetFeeCurrency.ticker
                  : "",
              });
            }}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];
  const gridStyleLeft: React.CSSProperties = {
    width: "30%",
    textAlign: "left",
  };
  const gridStyleRight: React.CSSProperties = {
    width: "70%",
    textAlign: "left",
  };
  return (
    <Content id="custody-transactions">
      <Row className="dcl-filter-row">
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Space>
            <Button
              className="dcl-btn-toggle"
              style={{ marginRight: "10px" }}
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            >
              <FontAwesomeIcon icon={"fa-solid fa-filter" as IconProp} />
              Filters
              {showFilter ? (
                <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
              ) : (
                <FontAwesomeIcon icon={"fa-solid fa-caret-down" as IconProp} />
              )}
            </Button>
          </Space>
          <Space className="dcl-filter-row-space">
            <b>Last updated: </b>
            {lastFetchTime
              ? new Date(lastFetchTime).toLocaleString("en-US", {
                  timeZone: displayTimezone,
                  timeZoneName: "short",
                })
              : "Pending..."}
          </Space>
          <Form
            title="Filter"
            form={filterForm}
            onFinish={filterTransactions}
            hidden={!showFilter}
            className="dcl-toggled-content dcl-toggled-content-filter"
          >
            <Row>
              <Form.Item name="dateRange" className="dcl-filter-item">
                <RangePicker
                  style={{ width: "100%" }}
                  disabledDate={disabledDate}
                  className="dcl-daterange-select"
                />
              </Form.Item>
              <Form.Item name="clientIds" className="dcl-filter-item">
                <Select
                  placeholder="Client"
                  className="dcl-client-select"
                  options={clientOptions}
                  showSearch
                  mode="multiple"
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  onChange={() => updateClientAccountList()}
                />
              </Form.Item>
              <Form.Item name="clientAccounts" className="dcl-filter-item">
                <Select
                  className="dcl-client-account-select"
                  placeholder="Client Account"
                  options={clientAccountOptions}
                  showSearch
                  mode="multiple"
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item
                name="address"
                className="dcl-filter-item"
                normalize={(value) => value.trim()}
              >
                <Input placeholder="Wallet Address" />
              </Form.Item>
              <Form.Item name="assetIds" className="dcl-filter-item">
                <Select
                  options={assetOptions}
                  placeholder="Asset Ticker"
                  className="dcl-asset-select"
                  showSearch
                  mode="multiple"
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item
                name="txnHash"
                className="dcl-filter-item"
                normalize={(value) => value.trim()}
              >
                <Input placeholder="Transaction Hash" />
              </Form.Item>
              <Form.Item name="subwalletStatus" className="dcl-filter-item">
                <Select
                  className="dcl-status-select"
                  placeholder="Subwallet Status"
                  options={[
                    { label: "Active", value: "active", key: 1 },
                    { label: "Inactive", value: "inactive", key: 2 },
                  ]}
                  allowClear
                />
              </Form.Item>
            </Row>
            <Row justify="end">
              <Space>
                <b>{tableLength} Transactions</b>
                <Button
                  htmlType="submit"
                  className="ant-btn-primary"
                  loading={!onFinishLoaded}
                >
                  Apply
                </Button>
                <Button onClick={onReset}>Reset</Button>
              </Space>
            </Row>
          </Form>
        </Col>
        <Col md={{ span: 8 }} sm={{ span: 8 }}>
          <Row justify={"end"}>
            <Button
              className="dcl-btn-toggle"
              onClick={exportCSV}
              style={{ marginRight: "10px" }}
              loading={isDownloadingCSV}
            >
              <FontAwesomeIcon icon={"fa-regular fa-copy" as IconProp} />
              CSV Export
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setDisplayCreateModal(true);
              }}
            >
              <FontAwesomeIcon
                icon={"fa-solid fa-plus" as IconProp}
                className="white-plus"
              />
              &nbsp; New Transaction
            </Button>
          </Row>
        </Col>
      </Row>
      <Table
        onChange={(pagination: any) => {
          onPaginationChange(pagination);
        }}
        loading={!onFinishLoaded}
        pagination={pagination}
        sticky
        rowKey="id"
        scroll={{ x: 1950 }}
        showSorterTooltip={false}
        dataSource={tableData}
        columns={initialColumns}
      />
      <Modal
        open={displayCreateModal}
        width={"80%"}
        onCancel={() => {
          setDisplayCreateModal(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setDisplayCreateModal(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              createTransaction();
            }}
            disabled={!createSelectedSubWallet}
          >
            Create
          </Button>,
        ]}
      >
        <Card title={"Create a new transaction"}>
          <Card.Grid style={gridStyleLeft}>
            <b>Client</b>
          </Card.Grid>
          <Card.Grid style={gridStyleRight}>
            <Select
              options={clientOptions}
              showSearch
              filterOption={(input, option: any) => {
                return option.label.toLowerCase().includes(input.toLowerCase());
              }}
              style={{ minWidth: "600px", width: "100%", maxWidth: "100%" }}
              onChange={(e) => updateCreateClientAccountList(e)}
              value={createSelectedClient}
            />
          </Card.Grid>
          <Card.Grid style={gridStyleLeft}>
            <b>Client Account</b>
          </Card.Grid>
          <Card.Grid style={gridStyleRight}>
            <Select
              options={createClientAccountOptions}
              showSearch
              filterOption={(input, option: any) => {
                return option.label.toLowerCase().includes(input.toLowerCase());
              }}
              style={{ minWidth: "600px" }}
              onChange={(e) => {
                setCreateSelectedClientAccount(e);
                setCreateSelectedWallet(undefined);
                setCreateSelectedSubWallet(undefined);
                getWalletAndSubWallets(e);
              }}
              value={createSelectedClientAccount}
            />
          </Card.Grid>
          <Card.Grid style={gridStyleLeft}>
            <b>Wallet</b>
          </Card.Grid>
          <Card.Grid style={gridStyleRight}>
            <Select
              options={createWalletOptions}
              showSearch
              filterOption={(input, option: any) => {
                return option.label.toLowerCase().includes(input.toLowerCase());
              }}
              style={{ minWidth: "600px" }}
              onChange={(e) => {
                setCreateSelectedWallet(e);
                setCreateSelectedSubWallet(undefined);
                const w = createWalletOptions.find((t: any) => t.id === e);
                const subWallets = w && w.subWallets ? w.subWallets : [];
                subWallets.forEach((s: any) => {
                  const asset = assetOptions?.find(
                    (t) => t.value === s.assetId,
                  );
                  s.value = s.id;
                  s.label = `${s.label} ${asset ? ` -- ${asset.label}` : ""}`;
                });
                setCreateSubWalletOptions(subWallets);
              }}
              value={createSelectedWallet}
            />
          </Card.Grid>
          <Card.Grid style={gridStyleLeft}>
            <b>Subwallet</b>
          </Card.Grid>
          <Card.Grid style={gridStyleRight}>
            <Select
              options={createSubWalletOptions}
              showSearch
              filterOption={(input, option: any) => {
                return option.label.toLowerCase().includes(input.toLowerCase());
              }}
              style={{ minWidth: "600px" }}
              onChange={(e) => {
                setCreateSelectedSubWallet(e);
              }}
              value={createSelectedSubWallet}
            />
          </Card.Grid>
          <Card.Grid style={gridStyleLeft}>
            <b>Timestamp</b>
          </Card.Grid>
          <Card.Grid style={gridStyleRight}>
            <DatePicker
              style={{ minWidth: "400px" }}
              showTime
              value={dayjs(createInputTimestamp)}
              onChange={(e) => {
                const timestamp = e
                  ? e.toISOString()
                  : new Date().toISOString();
                setCreateInputTimestamp(timestamp);
              }}
            />
          </Card.Grid>
          <Card.Grid style={gridStyleLeft}>
            <b>Hash</b>
          </Card.Grid>
          <Card.Grid style={gridStyleRight}>
            <Input
              style={{ minWidth: "600px" }}
              value={createInputHash}
              onChange={(e) => {
                setCreateInputHash(e.target.value);
              }}
            />
          </Card.Grid>
          <Card.Grid style={gridStyleLeft}>
            <b>Block Number</b>
          </Card.Grid>
          <Card.Grid style={gridStyleRight}>
            <Input
              style={{ minWidth: "600px" }}
              value={createInputBlockNumber}
              onChange={(e) => {
                setCreateInputBlockNumber(e.target.value);
              }}
            />
          </Card.Grid>
          <Card.Grid style={gridStyleLeft}>
            <b>Amount</b>
          </Card.Grid>
          <Card.Grid style={gridStyleRight}>
            <InputNumber
              style={{ minWidth: "600px" }}
              value={createInputAmount}
              onChange={(e) => {
                setCreateInputAmount(e ? e : 0);
              }}
            />
          </Card.Grid>
          <Card.Grid style={gridStyleLeft}>
            <b>Fee Amount</b>
          </Card.Grid>
          <Card.Grid style={gridStyleRight}>
            <InputNumber
              style={{ minWidth: "600px" }}
              value={createInputFeeAmount}
              onChange={(e) => {
                setCreateInputFeeAmount(e ? e : 0);
              }}
            />
          </Card.Grid>
          <Card.Grid style={gridStyleLeft}>
            <b>Fee Ticker</b>
          </Card.Grid>
          <Card.Grid style={gridStyleRight}>
            <Select
              style={{ minWidth: "600px" }}
              options={assetOptions}
              value={createInputFeeCurrency}
              onChange={(e) => {
                setCreateInputFeeCurrency(e);
              }}
            />
          </Card.Grid>
          <Card.Grid style={gridStyleLeft}>
            <b>Memo</b>
          </Card.Grid>
          <Card.Grid style={gridStyleRight}>
            <TextArea
              value={createInputDescription}
              onChange={(e) => {
                setCreateInputDescription(e.target.value);
              }}
            />
          </Card.Grid>
        </Card>
      </Modal>

      <Modal
        open={displayEditModal}
        width={"80%"}
        onCancel={() => {
          setDisplayEditModal(false);
          setEditingRecord(undefined);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setDisplayEditModal(false);
              setEditingRecord(undefined);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              submitEditingRecord();
            }}
          >
            Update
          </Button>,
        ]}
      >
        <Card title={"Edit Transaction"}>
          <Card.Grid style={gridStyleLeft}>
            <b>Timestamp</b>
          </Card.Grid>
          <Card.Grid style={gridStyleRight}>
            <DatePicker
              style={{ minWidth: "400px" }}
              showTime
              value={editingRecord ? dayjs(editingRecord.timestamp) : dayjs()}
              onChange={(e) => {
                const timestamp = e
                  ? e.toISOString()
                  : new Date().toISOString();
                updatingEditingRecord(timestamp, "timestamp");
              }}
            />
          </Card.Grid>
          <Card.Grid style={gridStyleLeft}>
            <b>Transaction Hash</b>
          </Card.Grid>
          <Card.Grid style={gridStyleRight}>
            <Input
              value={editingRecord ? editingRecord.hash : ""}
              onChange={(e) => {
                updatingEditingRecord(e.target.value, "hash");
              }}
            />
          </Card.Grid>
          <Card.Grid style={gridStyleLeft}>
            <b>Block Number</b>
          </Card.Grid>
          <Card.Grid style={gridStyleRight}>
            <Input
              value={editingRecord ? editingRecord.blockNumber : ""}
              onChange={(e) => {
                updatingEditingRecord(e.target.value, "blockNumber");
              }}
            />
          </Card.Grid>

          <Card.Grid style={gridStyleLeft}>
            <b>Amount</b>
          </Card.Grid>
          <Card.Grid style={gridStyleRight}>
            <Input
              value={editingRecord ? editingRecord.value : ""}
              onChange={(e) => {
                updatingEditingRecord(e.target.value, "value");
              }}
            />
          </Card.Grid>
          <Card.Grid style={gridStyleLeft}>
            <b>Fee Amount</b>
          </Card.Grid>
          <Card.Grid style={gridStyleRight}>
            <Input
              value={editingRecord ? editingRecord.fee : ""}
              onChange={(e) => {
                updatingEditingRecord(e.target.value, "fee");
              }}
            />
          </Card.Grid>
          <Card.Grid style={gridStyleLeft}>
            <b>Fee Ticker</b>
          </Card.Grid>
          <Card.Grid style={gridStyleRight}>
            <Select
              style={{ minWidth: "400px" }}
              options={assetOptions}
              value={editingRecord ? editingRecord.feeCurrencyId : undefined}
              onChange={(e) => {
                updatingEditingRecord(e, "feeCurrencyId");
              }}
              allowClear
            />
          </Card.Grid>
          <Card.Grid style={gridStyleLeft}>
            <b>Memo</b>
          </Card.Grid>
          <Card.Grid style={gridStyleRight}>
            <TextArea
              value={editingRecord ? editingRecord.description : ""}
              onChange={(e) => {
                updatingEditingRecord(e.target.value, "description");
              }}
            />
          </Card.Grid>
        </Card>
      </Modal>
      <Modal
        open={showOperationAssignModal}
        footer={[]}
        onCancel={() => {
          setShowOperationAssignModal(false);
        }}
      >
        <AssignOperation
          clientOptions={opClientOptions}
          setShowOperationAssignModal={setShowOperationAssignModal}
          referenceItem={referenceItem}
          referenceId={referenceId}
          referenceType={referenceType}
          fetchData={filterTransactions}
          form={filterForm.getFieldsValue()}
        />
      </Modal>
      <Modal
        open={showOperationDetailModal}
        footer={[]}
        closable={true}
        onCancel={() => {
          setReferenceType(undefined);
          setReferenceId(undefined);
          setShowOperationDetailModal(false);
        }}
      >
        <ViewOperationSummary
          clientOptions={clientOptions}
          referenceId={referenceId}
          reference={referenceType}
          setShowModal={setShowOperationDetailModal}
          fetchData={filterTransactions}
          form={filterForm.getFieldsValue()}
        />
      </Modal>
    </Content>
  );
}
