import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
} from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import dayjs from "dayjs";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";
import { DisplayContext } from "../../../Context/displayContext";
import CreateNewDclQuote from "./CreateNewDclQuote";
import QuoteAssignOperation from "./QuoteAssignOperation";

const { RangePicker } = DatePicker;

export default function ViewDclQuote() {
  const location = useLocation();
  // let index = 0;
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days after today and today
    return current > dayjs().endOf("day");
  };
  const [, copy] = useCopyToClipboard();

  // const [filtersApplied, setFiltersApplied] = useState<boolean>(false);

  const navigate = useNavigate();
  const displayContext = useContext(DisplayContext);
  const [onFinishLoad, setOnFinishLoad] = useState<boolean>(false);
  const [selectedDclQuote, setSelectedDclQuote] = useState<number>();
  const [selectedClientId, setSelectedClientId] = useState<number>();
  const [showAssignOperationModal, setShowAssignOperationModal] =
    useState<boolean>(false);
  const [selectedOpId, setSelectedOpId] = useState<number>();

  const [filterForm] = Form.useForm();
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const [clientList, setClientList] = useState<any[]>([]);
  const getClientList = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-list",
      withCredentials: true,
    }).then((res) => {
      for (const c of res.data.clients) {
        c.label = `${c.label} (${c.deltecAccount})`;
      }
      setClientList(res.data.clients);
    });
  }, []);

  // const addItem = (e: any) => {
  //   e.preventDefault();
  //   setOpenOrderList([...openOrderList, orderId || `New item ${index++}`]);
  //   setOrderId("");
  //   // setTimeout(() => {
  //   //   inputRef?.current?.focus();
  //   // }, 0);
  // };

  const [userList, setUserList] = useState<any[]>([]);
  const [defaultUserId, setDefaultUserId] = useState<any>();
  const getUserList = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/user-list",
      withCredentials: true,
    }).then((res) => {
      setUserList(res.data.users);
      setDefaultUserId(res.data.defaultUserId);
    });
  }, []);

  const [venueList, setVenueList] = useState<any[]>([]);
  const [defaultVenueId, setDefaultVenueId] = useState<any>();
  const getVenueList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/",
      withCredentials: true,
    })
      .then((res) => {
        setVenueList(res.data.list);
        setDefaultVenueId(res.data.defaultVenueId);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);

  const [transactionWayList, setTransactionWayList] = useState<any[]>([]);
  const getTransactionWayList = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/transaction-way",
      params: { type: "trade" }, // Buy and Sell Specific
      withCredentials: true,
    }).then((res) => {
      setTransactionWayList(res.data);
    });
  }, []);

  const [dclOrderTypeList, setdclOrderTypeList] = useState<any[]>([]);
  const [defaultdclOrderType, setDefaultdclOrderType] = useState<any>();
  const getdclOrderTypeList = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/dcl-order-type",
      withCredentials: true,
    }).then((res) => {
      setdclOrderTypeList(res.data.types);
      setDefaultdclOrderType(res.data.defaultTypeId);
    });
  }, []);
  const [assetList, setAssetList] = useState<any[]>([]);
  const [defaultAssetId, setDefaultAssetId] = useState<any>();
  const getAssetList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/select-list",
      withCredentials: true,
    }).then((res) => {
      const options = [];
      for (const asset of res.data.assetList) {
        options.push({
          label: `${asset.name} (${asset.ticker})`,
          value: asset.id,
          key: asset.id,
          ticker: asset.ticker,
          isStablecoin: asset.isStablecoin,
          assetType: asset.assetType,
        });
      }
      setAssetList(options);
      setDefaultAssetId(res.data.defaultAssetId);
    });
  }, []);

  const [defaultFeeObj, setDefaultFeeObj] = useState<any>();
  // const [defaultDclfees, setDefaultDclFees] = useState<number>(0);
  // const [defaultVenueFees, setDefaultVenueFees] = useState<number>(0);
  // const [minNetFee, setMinNetFee] = useState<number>(0);
  // const [minDclNetFee, setMinDclNetFee] = useState<number>(0);
  const getDefaultFees = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/order-builder-default-fees",
      withCredentials: true,
    }).then((res) => {
      setDefaultFeeObj(res.data);
    });
  }, []);

  useEffect(() => {
    getDefaultFees();
  }, [getDefaultFees]);

  useEffect(() => {
    getUserList();
    getVenueList();
    getClientList();
    getTransactionWayList();
    getdclOrderTypeList();
    getAssetList();
  }, [
    getUserList,
    getVenueList,
    getClientList,
    getTransactionWayList,
    getdclOrderTypeList,
    getAssetList,
    getDefaultFees,
  ]);

  // const [fetchDate, setFetchDate] = useState<any>();

  const [dclOrderList, setdclOrderList] = useState<any[]>([]);

  const [showNewOrderBuilder, setShowNewOrderBuilder] =
    useState<boolean>(false);
  const [newdclOrderFragment, setNewdclOrderFragment] = useState<any>();

  const [canDelete, setCanDelete] = useState<boolean>(false);
  const fetchCanDelete = useCallback(() => {
    axios({
      method: "GET",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/dcl-order-data/can-delete",
      withCredentials: true,
    }).then((res) => {
      setCanDelete(res.data.canDelete);
    });
  }, []);
  useEffect(() => {
    fetchCanDelete();
  }, [fetchCanDelete]);

  // Initial Columns
  const initialColumns = [
    {
      title: "Client",
      dataIndex: "clientName",
      hidden: false,
      sorter: false,
      defaultSortOrder: "ascend" as const,
      editable: false,
      width: "250px",
    },
    {
      title: "dcl User",
      dataIndex: "dclUserName",
      hidden: false,
      sorter: false,
      defaultSortOrder: "ascend" as const,
      editable: false,
      width: "200px",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: "120px",
      align: "center" as const,
      render: (_: any, dclOrder: any) => (
        <Row justify={"space-evenly"} align="middle">
          <Col span={8}>
            <Tooltip
              title={
                dclOrder.operationId
                  ? dclOrder.operationLabel
                  : "Assign to Operation"
              }
            >
              <Button
                style={{
                  border: "none",
                  background: "none",
                  boxShadow: "none",
                  padding: "2px 5px",
                  margin: "0 5px",
                }}
                onClick={() => {
                  setShowAssignOperationModal(true);
                  setSelectedDclQuote(dclOrder.id);
                  setSelectedOpId(dclOrder.operationId);
                  setSelectedClientId(dclOrder.clientId);
                }}
              >
                {dclOrder.operationId ? (
                  <FontAwesomeIcon icon={"fa-solid fa-info" as IconProp} />
                ) : (
                  <FontAwesomeIcon
                    icon={"fa-regular fa-square-plus" as IconProp}
                  />
                )}
              </Button>
            </Tooltip>
          </Col>
          <Col span={8}>
            <Tooltip title="Edit">
              <Link
                style={{ padding: "0.5em" }}
                to={
                  location.pathname + location.pathname.endsWith("/")
                    ? "edit/" + dclOrder.id
                    : "/edit/" + dclOrder.id
                }
              >
                <FontAwesomeIcon
                  icon={"fa-regular fa-pen-to-square" as IconProp}
                />
              </Link>
            </Tooltip>
          </Col>
          <Col span={8}>
            {canDelete &&
            !dclOrder.venueOrderId &&
            !dclOrder.isCanceled &&
            !dclOrder.isExecuted ? (
              <Tooltip title="Delete">
                <Button
                  style={{
                    border: "none",
                    background: "none",
                    boxShadow: "none",
                    padding: "2px 5px",
                    margin: "0 5px",
                  }}
                  onClick={() => {
                    setOpenDeleteModal(true);
                    setSelectedOrder(dclOrder);
                  }}
                >
                  <FontAwesomeIcon
                    className="delete-icon"
                    icon={"fa-regular fa-trash-can" as IconProp}
                  />
                </Button>
              </Tooltip>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      hidden: false,
      sorter: false,
      align: "center" as const,
      width: "80px",
      defaultSortOrder: "ascend" as const,
      editable: false,
      render: (_: any, { isCanceled, isExecuted }: any) => (
        <Space>
          {!isCanceled && !isExecuted ? (
            <Tooltip title={"Draft"}>
              <FontAwesomeIcon
                className="dcl-order-draft"
                icon={"fa-solid fa-file-circle-question" as IconProp}
              />
            </Tooltip>
          ) : isCanceled ? (
            <Tooltip title={"Canceled"}>
              <FontAwesomeIcon
                className="dcl-order-canceled"
                icon={"fa-solid  fa-file-circle-xmark" as IconProp}
              />
            </Tooltip>
          ) : isExecuted ? (
            <Tooltip title={"Executed"}>
              <FontAwesomeIcon
                className="dcl-order-executed"
                icon={"fa-solid fa-file-circle-check" as IconProp}
              />
            </Tooltip>
          ) : (
            <></>
          )}
        </Space>
      ),
    },
    {
      title: "Symbol",
      dataIndex: "symbolPair",
      hidden: false,
      sorter: false,
      defaultSortOrder: "ascend" as const,
      editable: false,
      width: "100px",
    },
    {
      title: "Transaction Way",
      dataIndex: "transactionWayName",
      hidden: false,
      sorter: false,
      defaultSortOrder: "ascend" as const,
      editable: false,
      width: "150px",
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      hidden: false,
      sorter: false,
      defaultSortOrder: "ascend" as const,
      editable: false,
      align: "right" as const,
      render: (_: any, { qty }: any) => (
        <Space>
          {qty.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          })}
        </Space>
      ),
      width: "200px",
    },
    {
      title: "Price",
      dataIndex: "price",
      hidden: false,
      sorter: false,
      defaultSortOrder: "ascend" as const,
      editable: false,
      align: "right" as const,
      render: (_: any, { price }: any) => (
        <Space>
          {price.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          })}
        </Space>
      ),
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      hidden: false,
      sorter: false,
      defaultSortOrder: "ascend" as const,
      editable: false,
      render: (_: any, { timestamp }: any) => (
        <Space>
          {new Date(timestamp).toLocaleString("en-US", {
            timeZone: displayContext?.displayContext.timezone,
            timeZoneName: "short",
          })}
        </Space>
      ),
    },
  ];

  const fetchdclDisplayedOrders = useCallback(
    (
      params = {
        initialFetch: true,
      },
    ) => {
      // console.log(params);
      setOnFinishLoad(false);
      if (params.initialFetch) {
        axios({
          method: "GET",
          url:
            process.env.REACT_APP_AWS_BACKEND_URL +
            "/venue/dcl-order-data/displayed",
          params: {
            initialFetch: true,
          },
          withCredentials: true,
        })
          .then((res) => {
            setdclOrderList([...res.data]);
          })
          .finally(() => {
            setOnFinishLoad(true);
          });
      } else {
        let startDate, endDate;
        if (filterForm.getFieldValue("dateRange")) {
          startDate = new Date(
            filterForm.getFieldValue("dateRange")[0].toString(),
          );
          endDate = new Date(
            filterForm.getFieldValue("dateRange")[1].toString(),
          );
        }
        axios({
          method: "GET",
          url:
            process.env.REACT_APP_AWS_BACKEND_URL +
            "/venue/dcl-order-data/displayed",
          params: {
            startDate: startDate ? startDate : undefined,
            endDate: endDate ? endDate : undefined,
            status: JSON.stringify(filterForm.getFieldValue("status")),
            // diplayExecuted: filterForm.getFieldValue("displayExecuted"),
            initialFetch: false,
          },
          withCredentials: true,
        })
          .then((res) => {
            setdclOrderList([...res.data]);
          })
          .finally(() => {
            setOnFinishLoad(true);
          });
      }
    },
    [filterForm],
  );

  const [selectedOrder, setSelectedOrder] = useState<any>();

  const deleteDclOrderBuilder = useCallback(() => {
    setIsDeleteModalLoading(true);
    axios({
      method: "Delete",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/dcl-order-data",
      data: {
        orderId: selectedOrder?.id,
      },
      withCredentials: true,
    })
      .then(() => {
        toast.success("Deleted Successfully");
        setSelectedOrder(undefined);
        fetchdclDisplayedOrders({ initialFetch: false });
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      })
      .finally(() => {
        setOpenDeleteModal(false);
        setIsDeleteModalLoading(false);
      });
  }, [selectedOrder, fetchdclDisplayedOrders]);

  const filterdclOrder = () => {
    fetchdclDisplayedOrders({ initialFetch: false });
    // setFiltersApplied(true);
  };
  const resetFilter = () => {
    filterForm.resetFields();
    fetchdclDisplayedOrders({ initialFetch: true });
    // setFiltersApplied(false);
  };

  const copyToClipBoard = () => {
    axios({
      method: "Post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/dcl-order-data/copy-to-clipboard",
      data: dclOrderList,
      withCredentials: true,
    })
      .then((res) => {
        copy(res.data);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        } else if (err.response.status === 408) {
          toast.error(err.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .finally(() => {
        toast.success("Data copied to clipboard", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  useEffect(() => {
    if (showNewOrderBuilder) {
      setNewdclOrderFragment(
        <CreateNewDclQuote
          setShowNewOrderBuilder={setShowNewOrderBuilder}
          clientList={clientList}
          userList={userList}
          venueList={venueList}
          transactionWayList={transactionWayList}
          dclOrderTypeList={dclOrderTypeList}
          assetList={assetList}
          fetchdclDisplayedOrders={fetchdclDisplayedOrders}
          defaultOrderType={defaultdclOrderType}
          defaultAssetId={defaultAssetId}
          defaultVenueId={defaultVenueId}
          defaultUserId={defaultUserId}
          defaultFeeObj={defaultFeeObj}
          // defaultDclfees={defaultDclfees}
          // defaultVenueFees={defaultVenueFees}
          // minNetFee={minNetFee}
          // minDclNetFee={minDclNetFee}
        />,
      );
    } else {
      setNewdclOrderFragment(undefined);
    }
  }, [
    showNewOrderBuilder,
    clientList,
    userList,
    venueList,
    transactionWayList,
    dclOrderTypeList,
    assetList,
    fetchdclDisplayedOrders,
    defaultdclOrderType,
    defaultAssetId,
    defaultVenueId,
    defaultUserId,
    defaultFeeObj,
    // defaultDclfees,
    // defaultVenueFees,
    // minNetFee,
    // minDclNetFee,
  ]);

  useEffect(() => {
    fetchdclDisplayedOrders();
  }, [fetchdclDisplayedOrders]);

  const [isDeleteModalLoading, setIsDeleteModalLoading] =
    useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  return (
    <Content id="order-builder">
      <Row className="dcl-filter-row">
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Row className="dcl-filter-row" align="middle">
            <Space>
              <Button
                className="dcl-btn-toggle"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
              >
                <FontAwesomeIcon icon={"fa-solid fa-filter" as IconProp} />
                Filters
                {showFilter ? (
                  <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
                ) : (
                  <FontAwesomeIcon
                    icon={"fa-solid fa-caret-down" as IconProp}
                  />
                )}
              </Button>
            </Space>
          </Row>
          <Form
            title="Filter"
            form={filterForm}
            onFinish={filterdclOrder}
            className="dcl-toggled-content dcl-toggled-content-filter"
            hidden={!showFilter}
          >
            <Row>
              <Form.Item
                name="dateRange"
                className="dcl-filter-item"
                initialValue={[dayjs().startOf("y"), dayjs()]}
              >
                <RangePicker
                  disabledDate={disabledDate}
                  className="dcl-daterange-select"
                />
              </Form.Item>
              <Form.Item
                name="status"
                style={{ minWidth: "10em", maxWidth: "21em" }}
                initialValue={["draft"]}
              >
                <Select
                  mode="multiple"
                  options={[
                    {
                      label: "Draft",
                      key: "draft",
                      value: "draft",
                    },
                    {
                      label: "Canceled",
                      key: "canceled",
                      value: "canceled",
                    },
                    {
                      label: "Executed",
                      key: "executed",
                      value: "executed",
                    },
                  ]}
                  style={{ width: "100%" }}
                  placeholder="Status"
                  showSearch
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  allowClear
                />
              </Form.Item>
              {/* <Form.Item
                name="displayCanceled"
                label="Display Canceled Orders"
                className="dcl-filter-item"
                valuePropName="checked"
                initialValue={false}
              >
                <Switch />
              </Form.Item>
              <Form.Item
                name="displayExecuted"
                label="Display executed orders"
                className="dcl-filter-item"
                valuePropName="checked"
                initialValue={false}
              >
                <Switch />
              </Form.Item> */}
            </Row>
            <Row justify="end">
              <Space>
                {dclOrderList.length}
                <b>Results</b>
              </Space>
              <Space>
                <Button htmlType="submit" className="ant-btn-primary">
                  Apply
                </Button>
                <Button onClick={resetFilter}>Reset</Button>
              </Space>
            </Row>
          </Form>
        </Col>
        <Col md={{ span: 8, order: 1 }} sm={{ span: 8, order: 1 }}>
          <Row justify="end">
            {showNewOrderBuilder ? (
              <Button
                danger
                onClick={() => {
                  setShowNewOrderBuilder(false);
                }}
              >
                Cancel
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={() => {
                  setShowNewOrderBuilder(true);
                }}
              >
                <FontAwesomeIcon
                  icon={"fa-solid fa-plus" as IconProp}
                  className="white-plus"
                />
                Quote
              </Button>
            )}

            <Button
              className="dcl-btn-toggle"
              style={{ marginRight: "10px" }}
              onClick={copyToClipBoard}
            >
              <FontAwesomeIcon icon={"fa-regular fa-copy" as IconProp} />
              Copy to Clipboard
            </Button>
          </Row>
        </Col>
      </Row>
      {newdclOrderFragment}
      {onFinishLoad ? (
        <Table
          sticky
          id="order-builder-list"
          rowKey="id"
          columns={initialColumns}
          dataSource={dclOrderList}
          size={"small"}
          scroll={{
            x: 1200,
          }}
          pagination={{
            pageSizeOptions: [10, 50, 100],
            showSizeChanger: true,
          }}
          rowClassName={(_, index) => (index % 2 ? "odd" : "even")}
        />
      ) : (
        <Layout
          style={{
            paddingTop: "30vh",
            minHeight: "100vh",
          }}
        >
          <Spin size="large" />
        </Layout>
      )}
      <ConfigProvider
        theme={{
          components: {
            Form: {
              labelColor: "white",
              itemMarginBottom: 6,
            },
            Button: {
              defaultColor: "black",
            },
          },
          token: {
            colorText: "white",
          },
        }}
      >
        <Modal
          width={"40%"}
          open={openDeleteModal}
          onClose={() => {
            setOpenDeleteModal(false);
            setSelectedOrder(undefined);
          }}
          onCancel={() => {
            setOpenDeleteModal(false);
            setSelectedOrder(undefined);
          }}
          footer={[
            <Button
              key="back"
              onClick={() => {
                setOpenDeleteModal(false);
                setSelectedOrder(undefined);
              }}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              danger
              onClick={() => {
                deleteDclOrderBuilder();
              }}
              loading={isDeleteModalLoading}
            >
              Delete
            </Button>,
          ]}
        >
          <Row justify={"start"}>
            <h3 style={{ color: "white" }}>Are you sure?</h3>
          </Row>

          <Form.Item label={<b>Client</b>}>
            {selectedOrder?.clientName}
          </Form.Item>
          <Form.Item label={<b>Symbol</b>}>
            {selectedOrder?.symbolPair}
          </Form.Item>
          <Form.Item label={<b>Amount</b>}>
            {selectedOrder?.qty?.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 6,
            })}
          </Form.Item>
          <Form.Item label={<b>Price</b>}>
            {selectedOrder?.price?.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 6,
            })}
          </Form.Item>
          <Form.Item label={<b>Timestamp</b>}>
            {new Date(selectedOrder?.timestamp)?.toLocaleString("en-US", {
              timeZone: displayContext?.displayContext.timezone,
              timeZoneName: "short",
            })}
          </Form.Item>
        </Modal>
      </ConfigProvider>
      <Modal
        open={showAssignOperationModal}
        onCancel={() => {
          setSelectedClientId(undefined);
          setSelectedDclQuote(undefined);
          setShowAssignOperationModal(false);
        }}
        destroyOnClose
        footer={false}
      >
        <QuoteAssignOperation
          clientId={selectedClientId}
          dclQuoteId={selectedDclQuote}
          setSelectedClientId={setSelectedClientId}
          setSelectedDclQuote={setSelectedDclQuote}
          setShowAssignOperationModal={setShowAssignOperationModal}
          fetchdclDisplayedOrders={fetchdclDisplayedOrders}
          selectedOpId={selectedOpId}
          setSelectedOpId={setSelectedOpId}
        />
      </Modal>
    </Content>
  );
}
