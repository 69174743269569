import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  Collapse,
  ConfigProvider,
  Form,
  Row,
  Spin,
  Table,
  Tooltip,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DisplayContext } from "../../Context/displayContext";
import DclQuoteConfirmationModal from "../MasterLedgerPage/OrderBuilderComponents/DclQuoteConfirmModal";
export default function ViewOperationDetail({
  operationId,
  transactionWayList,
}: any) {
  const detailColumns = [
    {
      title: "Type",
      dataIndex: "typeLabel",
      key: "typeLabel",
      width: "200px",
    },
    {
      title: "ID",
      dataIndex: "referenceRealId",
      key: "referenceRealId",
      width: "200px",
    },
    {
      title: "Timestamp",
      dataIndex: "referenceTimestamp",
      key: "referenceTimestamp",
      width: "150px",
      render: (_: any, { referenceTimestamp }: any) =>
        new Date(referenceTimestamp)?.toLocaleString("en-US", {
          timeZone: displayTimezone,
          timeZoneName: "short",
        }),
    },
    {
      title: "Venue",
      dataIndex: "referenceVenue",
      key: "referenceVenue",
      width: "90px",
    },
    {
      title: "Amount",
      dataIndex: "referenceAmount",
      key: "referenceAmount",
      align: "right" as const,
      render: (_: any, { referenceAmount }: any) =>
        referenceAmount
          ? referenceAmount.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            })
          : "0.00",
      width: "150px",
    },
    {
      title: "Currency",
      dataIndex: "referenceCurrency",
      key: "referenceCurrency",
      width: "100px",
    },
    {
      title: "Is Client Transfer",
      dataIndex: "isClientTransfer",
      key: "isClientTransfer",
      width: "90px",
      render: (_: any, { operationItem, operationItemSubtype }: any) => (
        <Row>
          {!operationItemSubtype.addOnLabel &&
          operationItem.isClientTransfer ? (
            // is Client Transfer
            operationItem.isVenueWalletRelated ? (
              // Kept on venue
              operationItem.operationItemType?.key?.includes("deposit") ? (
                <Tooltip title="Take asset from venue wallet">
                  <FontAwesomeIcon
                    icon={"fa-solid fa-circle-arrow-down" as IconProp}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Keep asset on venue wallet">
                  <FontAwesomeIcon
                    icon={"fa-solid fa-circle-arrow-up" as IconProp}
                  />
                </Tooltip>
              )
            ) : // took out
            operationItem.operationItemType?.key?.includes("deposit") ? (
              <Tooltip title="Client Deposit">
                <FontAwesomeIcon
                  icon={"fa-solid fa-arrow-right-to-bracket" as IconProp}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Withdrawal to Client">
                <FontAwesomeIcon
                  icon={"fa-solid fa-arrow-right-from-bracket" as IconProp}
                />
              </Tooltip>
            )
          ) : (
            <></>
          )}
        </Row>
      ),
    },
    // Add other columns as needed
  ];
  const navigate = useNavigate();
  const [fetchingData, setFetchingData] = useState<boolean>(false);
  const [operationItems, setOperationItems] = useState<any[]>([]);
  const [operationLabel, setOperationLabel] = useState<string>("");
  const [operationDescription, setOperationDescription] = useState<string>("");
  const [operationCategoryDetail, setOperationCategoryDetail] = useState<any[]>(
    [],
  );
  const [operation, setOperation] = useState<any>();
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  const [operationWay, setOperationWay] = useState<string>("");
  const [dclFeeEqual, setDclFeeEqual] = useState<boolean>(true);
  const [calculatedDclFee, setCalculatedDclFee] = useState<number>(0);
  const [transactionWayId, setTrasactionWayId] = useState<number>();
  const [orderSymbolMap, setOrderSymbolMap] = useState<any>();

  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);

  const resetOperationDetail = useCallback(() => {
    setOperationItems([]);
    setOperationLabel("");
    setOperationDescription("");
    setOperationWay("");
    setDclFeeEqual(true);
    setCalculatedDclFee(0);
    setOperationCategoryDetail([]);
    setOperation(undefined);
    setTrasactionWayId(undefined);
    setOrderSymbolMap(undefined);
  }, []);

  const getOperationDetail = useCallback(
    (operationId: string) => {
      setFetchingData(true);
      resetOperationDetail();
      axios({
        method: "GET",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/item",
        withCredentials: true,
        params: {
          operationId,
        },
      })
        .then((res) => {
          const filteredOperationItems = res.data.operationItems?.filter(
            (row: any) => Number(row.referenceAmount) !== 0,
          );
          setOperation(res.data.operation);
          setOperationItems(filteredOperationItems);
          setOperationLabel(res.data.operation?.label);
          setTrasactionWayId(res.data.operation?.transactionWayId);
          setOperationDescription(res.data.operation?.description);
          setOperationWay(res.data.operationWay);
          setDclFeeEqual(res.data.dclFeeEqual);
          setCalculatedDclFee(res.data.calculatedDclFee);
          setOperationCategoryDetail(res.data.operationCategoriesMap);
          setOrderSymbolMap(res.data.orderSymbolMap);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/login");
          }
          toast.error("Failed to get operation items");
        })
        .finally(() => {
          setFetchingData(false);
        });
    },
    [resetOperationDetail, navigate],
  );
  useEffect(() => {
    getOperationDetail(operationId);
  }, [getOperationDetail, operationId]);
  const [colorMap, setColorMap] = useState<any>({});
  useEffect(() => {
    const opIds = [
      ...new Set(operationItems.map((row) => row.operationItemId)),
    ];
    let count = 0;
    const map = new Map();
    for (const opId of opIds) {
      map.set(opId, count % 2 ? "operation-odd" : "operation-even");
      count += 1;
    }
    setColorMap(map);
  }, [operationItems]);

  const getRowClassName = useCallback(
    (opId: number) => {
      const className = colorMap?.get(opId);
      return className;
    },
    [colorMap],
  );
  return (
    <ConfigProvider
      theme={{
        components: {
          Form: {
            labelColor: "white",
            labelFontSize: 16,
          },
        },
      }}
    >
      {fetchingData ? (
        <Row justify={"center"}>
          <Spin />
        </Row>
      ) : (
        <Content id="view-operation-detail">
          <Row justify="center">
            <h2 style={{ color: "white" }}>{operationLabel}</h2>
          </Row>
          <Row>
            <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
              <Form.Item label={<b>Client</b>}>
                <b style={{ color: "white" }}>
                  {operation?.client?.clientName}
                </b>
              </Form.Item>
            </Col>
            <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
              <Form.Item label={<b>Operation</b>}>
                <b style={{ color: "white" }}>{operation?.label}</b>
              </Form.Item>
            </Col>
            {!fetchingData && operation?.operationTypeId === 1 ? (
              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Form.Item
                  label={<b>Operation Way</b>}
                  name="transactionWayId"
                  // initialValue={transactionWayId}
                >
                  <b
                    className={`dcl-operation-${transactionWayList
                      .find((row: any) => row.value === transactionWayId)
                      ?.label?.toLowerCase()}`}
                  >
                    {
                      transactionWayList.find(
                        (row: any) => row.value === transactionWayId,
                      )?.label
                    }
                  </b>
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
            <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
              {operation?.operationTypeId === 1 ? (
                <Form.Item label={<b>Way</b>}>
                  <b style={{ color: "white" }}>{operationWay}</b>
                </Form.Item>
              ) : (
                <></>
              )}
            </Col>
            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
              <Form.Item label={<b>Dates</b>}>
                <Row>
                  <b style={{ color: "white" }}>
                    {operationItems?.length
                      ? new Date(
                          Math.min.apply(
                            null,
                            operationItems.map((row) =>
                              new Date(row.referenceTimestamp).valueOf(),
                            ),
                          ),
                        )?.toLocaleString("en-US", {
                          timeZone: displayTimezone,
                          timeZoneName: "short",
                        })
                      : "N/A"}
                  </b>
                </Row>
                <Row>
                  <b style={{ color: "white" }}>
                    {operationItems?.length
                      ? new Date(
                          Math.max.apply(
                            null,
                            operationItems.map((row) =>
                              new Date(row.referenceTimestamp).valueOf(),
                            ),
                          ),
                        )?.toLocaleString("en-US", {
                          timeZone: displayTimezone,
                          timeZoneName: "short",
                        })
                      : "N/A"}
                  </b>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row align={"top"}>
            <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
              <Form.Item label={<b>Description</b>}>
                <TextArea
                  disabled
                  value={operationDescription}
                  style={{
                    minWidth: "300px",
                    width: "100%",
                    maxWidth: "650px",
                    border: "1px solid #d9e9eb",
                    color: "white",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xxl={16} xl={16} lg={24} md={24} sm={24} xs={24}>
              <Row align={"stretch"}>
                {operationCategoryDetail.map((row) => (
                  <Col
                    key={row.name}
                    xs={24}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ marginBottom: "10px" }}
                  >
                    <Form.Item
                      label={<b>{row.name}</b>}
                      style={{ marginBottom: "0px" }}
                    >
                      {row.name === "Transaction Fees" ? (
                        <React.Fragment>
                          <b style={{ color: "white" }}>Borne by the client:</b>
                          {Object.entries(row.values).length ? (
                            Object.entries(row.values).map(
                              ([ticker, amount], index) => (
                                <Row key={`${ticker}_${index}`}>
                                  <b
                                    style={{
                                      color: "white",
                                      marginLeft: "1em",
                                    }}
                                  >
                                    {Number(amount)?.toLocaleString("en-us", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 8,
                                    })}
                                    &nbsp; {ticker}
                                  </b>
                                </Row>
                              ),
                            )
                          ) : (
                            <Row>
                              <b style={{ color: "white", marginLeft: "1em" }}>
                                N/A
                              </b>
                            </Row>
                          )}
                          <b style={{ color: "white" }}>
                            Borne by the Delchain:
                          </b>
                          {Object.entries(row.delchainValues).length ? (
                            Object.entries(row.delchainValues).map(
                              ([ticker, amount], index) => (
                                <Row key={index}>
                                  <b
                                    style={{
                                      color: "white",
                                      marginLeft: "1em",
                                    }}
                                  >
                                    {Number(amount)?.toLocaleString("en-us", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 8,
                                    })}
                                    &nbsp; {ticker}
                                  </b>
                                </Row>
                              ),
                            )
                          ) : (
                            <Row style={{ marginLeft: "1em" }}>
                              <b style={{ color: "white" }}>N/A</b>
                            </Row>
                          )}
                        </React.Fragment>
                      ) : Object.entries(row.values).length ? (
                        Object.entries(row.values).map(
                          ([ticker, amount]: any, index) => (
                            <Row key={index}>
                              <b style={{ color: "white" }}>
                                {Number(
                                  row.name === "Client Proceeds"
                                    ? Math.abs(amount)
                                    : amount,
                                )?.toLocaleString("en-us", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 8,
                                })}
                                &nbsp; {ticker}
                              </b>
                              {row.name === "Delchain Fees" ? (
                                <Tooltip
                                  title={calculatedDclFee?.toLocaleString(
                                    "en-us",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    },
                                  )}
                                >
                                  <i
                                    style={{ marginLeft: "0.5em" }}
                                    className={`dcl_state ${
                                      dclFeeEqual ? "state_green" : "state_red"
                                    }`}
                                  />
                                </Tooltip>
                              ) : (
                                <></>
                              )}
                            </Row>
                          ),
                        )
                      ) : (
                        <Row>
                          <b style={{ color: "white" }}>N/A</b>
                          {row.name === "Delchain Fees" ? (
                            <Tooltip
                              title={calculatedDclFee?.toLocaleString("en-us", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            >
                              <i
                                style={{ marginLeft: "0.5em" }}
                                className={`dcl_state ${
                                  dclFeeEqual ? "state_green" : "state_red"
                                }`}
                              />
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                        </Row>
                      )}
                      {row.name === "Order Quote" ? (
                        <React.Fragment>
                          {orderSymbolMap?.map(
                            ({ txnWay, base, quote }: any, idx: number) => {
                              if (txnWay === "Buy") {
                                return (
                                  <Row key={`${txnWay}_${idx}`}>
                                    <b style={{ color: "white" }}>
                                      {quote.value?.toLocaleString("en-us", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 8,
                                      })}{" "}
                                      {quote.ticker} sold to buy{" "}
                                      {base.value?.toLocaleString("en-us", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 8,
                                      })}{" "}
                                      {base.ticker}
                                    </b>
                                  </Row>
                                );
                              } else {
                                return (
                                  <Row key={`${txnWay}_idx`}>
                                    <b style={{ color: "white" }}>
                                      {base.value?.toLocaleString("en-us", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 8,
                                      })}{" "}
                                      {base.ticker}sold to buy{" "}
                                      {quote.value?.toLocaleString("en-us", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 8,
                                      })}{" "}
                                      {quote.ticker}{" "}
                                    </b>
                                  </Row>
                                );
                              }
                            },
                          )}
                        </React.Fragment>
                      ) : undefined}
                    </Form.Item>
                    {row.name === "Delchain Fees" ? (
                      <Form.Item
                        label={
                          <b style={{ color: "#19bdc2" }}>
                            Calculated Delchain Fees:
                          </b>
                        }
                        colon={false}
                      >
                        <b style={{ color: "#19bdc2" }}>
                          {calculatedDclFee?.toLocaleString("en-us", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{" "}
                          USD
                        </b>
                      </Form.Item>
                    ) : (
                      <></>
                    )}
                  </Col>
                ))}
              </Row>
            </Col>
            {operation?.dclQuotes?.length ? (
              <Col xxl={8} xl={8} lg={24} md={24} sm={24} xs={24}>
                <ConfigProvider
                  theme={{
                    components: {
                      Collapse: {
                        headerBg: "white",
                      },
                    },
                  }}
                >
                  <Collapse
                    items={operation?.dclQuotes?.map(
                      (dclQuote: any, index: number) => ({
                        key: index,
                        label: <b>Quote {index + 1}</b>,
                        children: (
                          <DclQuoteConfirmationModal
                            isBase={dclQuote.isBase}
                            date={dayjs(dclQuote.timestamp)
                              .tz("America/New_York", true)
                              .startOf("day")
                              .format()}
                            orderType={dclQuote.dlcOrderType?.name}
                            venue={dclQuote.venue?.name}
                            symbol={`${dclQuote.firstAsset?.ticker}/${dclQuote.secondAsset?.ticker}`}
                            transactionWay={dclQuote.transactionWay?.name}
                            qty={dclQuote.qty}
                            price={dclQuote.price}
                            venueFee={dclQuote.fee}
                            dclFee={dclQuote.dclFee}
                            note={dclQuote.note}
                            msg={dclQuote.propositionMessage}
                            summary={dclQuote.tradeSummary}
                            inCard={true}
                            status={
                              dclQuote.isExecuted ? (
                                <b>Executed</b>
                              ) : dclQuote.isCanceled ? (
                                "Canceled"
                              ) : (
                                <b className="dcl-order-draft">Draft</b>
                              )
                            }
                          />
                        ),
                      }),
                    )}
                    style={{ marginBottom: "1em" }}
                  />
                </ConfigProvider>
              </Col>
            ) : undefined}
          </Row>
          <Table
            rowKey={"id"}
            columns={detailColumns}
            dataSource={operationItems}
            rowClassName={(record) => {
              const className = getRowClassName(record.operationItemId);
              return className;
            }}
            scroll={{ x: "max-header", y: "50vh" }}
            pagination={{ hideOnSinglePage: true, pageSize: 100 }}
          />
        </Content>
      )}
    </ConfigProvider>
  );
}
